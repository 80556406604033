import { React, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import LayoutFullpage from 'layout/LayoutFullpage';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import HtmlHead from 'components/html-head/HtmlHead';
import ClipLoader from 'react-spinners/ClipLoader';
import { loginAdmin } from '../../views-admin/api/Api-admin';
import { setCurrentUser, setIsLoginUser } from '../../slices/auth/authSlice';

// utils
import { handleCatchError, handleAPIError } from '../../utils/handleErrors';

const LoginAdmin = () => {
  const title = 'Iniciar sesión';
  const description = 'Autopaquete te permitirá gestionar el almacenamiento, inventario y distribución de tu mercancía.';
  const [isLoading, setIsLoading] = useState(false);
  // Check login
  const { isLogin, currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  // console.log('* current user *:', currentUser);
  // console.log('* admin isLogin *:', isLogin);
  // console.log('logout localstorage:', JSON.parse(JSON.parse(localStorage.getItem('persist:starter-project')).auth));

  useEffect(() => {
    if (isLogin) {
      setTimeout(() => {
        history.push('/admin-dashboard');
        setIsLoading(false);
      }, 400);
    }
  }, [isLogin]);
  // End Check login
  const validationSchema = Yup.object().shape({
    // username: Yup.string().min(3, 'El nombre de usuario debe tener al menos 3 caracteres').required('El nombre de usuario es obligatorio')),
    username: Yup.string().min(3, '¡Debe tener al menos 6 caracteres!').required('Nombre de usuario es obligatoria'),
    password: Yup.string().min(6, '¡Debe tener al menos 6 caracteres!').required('Contraseña es obligatoria'),
  });
  const initialValues = { username: '', password: '' };

  // User Authentication
  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await loginAdmin(values.username, values.password);
      console.log('login admin:', response);
      if (response.username !== undefined) {
        dispatch(
          setCurrentUser({
            name: response.username,
            role: response.role, // for admin Control Total, Control Alto, Control Medio. or user
            api_key_production: response.token,
            access: response.access,
          })
        );
        dispatch(setIsLoginUser(true));
      } else if (response.error) {
        handleAPIError(response);
        // const firstKey = Object.keys(response.error)[0];
        // ToastErrorApi(response.error[firstKey]);
        setIsLoading(false);
      }
    } catch (error) {
      handleCatchError(error);
      setIsLoading(false);
    }
  };
  // End User Authentication
  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  const leftSide = (
    <div className="min-h-100 d-flex align-items-center">
      <div className="w-100 w-lg-75 w-xxl-50">
        <div>
          <div className="mb-5 logo-title-white" />
          <div className="mb-5">
            <h1 className="display-login text-white">ADMINISTRADOR</h1>
          </div>
          <p className="h6 text-white lh-1-5 mb-5">Autopaquete te permitirá gestionar el almacenamiento, inventario y distribución de tu mercancía.</p>
        </div>
      </div>
    </div>
  );

  const rightSide = (
    <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5  full-page-content-right-border">
      <div className="sw-lg-50 px-5">
        {/* <div className="logo-default" /> */}
        <div className="mb-5">
          <h2 className="cta-1 mb-0 text-primary">¡Bienvenido</h2>
          <h2 className="cta-1 text-primary">administrador!</h2>
        </div>
        <div className="mb-5">
          <p className="h6">Utiliza tus credenciales para iniciar sesión.</p>
          {/* <p className="h6">
            If you are not a member, please <NavLink to="/register">register</NavLink>.
          </p> */}
        </div>
        <div>
          <form id="loginForm" className="tooltip-end-bottom" onSubmit={handleSubmit}>
            <div className="mb-3 filled form-group tooltip-end-top">
              <CsLineIcons icon="user" />
              <Form.Control type="text" name="username" placeholder="Nombre" value={values.username} onChange={handleChange} />
              {errors.username && touched.username && <div className="d-block invalid-tooltip">{errors.username}</div>}
            </div>
            <div className="mb-3 filled form-group tooltip-end-top">
              <CsLineIcons icon="lock-off" />
              <Form.Control type="password" name="password" onChange={handleChange} value={values.password} placeholder="Contraseña" />
              {/* <NavLink className="text-small position-absolute t-3 e-3" to="/forgot-password">
                Forgot?
              </NavLink> */}
              {errors.password && touched.password && <div className="d-block invalid-tooltip">{errors.password}</div>}
            </div>
            <Button size="lg" type="submit">
              {isLoading && <ClipLoader color="#FFFFFF" loading={isLoading} size={10} />}
              {isLoading ? ' Cargando' : ' Iniciar sesión'}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <HtmlHead title={title} description={description} />
      <LayoutFullpage left={leftSide} right={rightSide} />
    </>
  );
};

export default LoginAdmin;
