import React from 'react';

import { Application, DatePicker } from 'react-rainbow-components';
import moment from 'moment';
import { useSelector } from 'react-redux';

// props - selectDate y setSelectDate are states for the component view
// props - setState  It is to change the value of the state that will be sent in the request with this format YYYY-MM-DD
// props - isDisabled is a boolean

const RainCalendar = ({ selectDate, setSelectDate, setState, label = '', isDisabled, holder, width = '275px' }) => {
  // color can be dark-red or light-red
  const { color } = useSelector((state) => state.settings);
  // get the current date
  const currentDate = new Date();
  // Clone the current date and subtract 6 months
  const minDate = new Date(currentDate);
  minDate.setMonth(currentDate.getMonth() - 12);
  const theme = {
    rainbow: {
      palette: {
        brand: '#850000',
      },
    },
  };
  // Theme for dark mode
  const darkTheme = {
    rainbow: {
      palette: {
        brand: '#F0F0F0', // Cambia los colores del tema aquí
        mainBackground: '#1d1d1d',
      },
    },
  };

  return (
    <Application theme={color === 'dark-red' ? darkTheme : theme}>
      <DatePicker
        label={label}
        className="datepicker rainbow-p-around_medium p-0 pt-0 text-alternate"
        borderRadius="semi-rounded"
        disabled={isDisabled}
        placeholder={holder}
        value={selectDate.date}
        minDate={minDate}
        maxDate={currentDate}
        onChange={(value) => {
          // console.log('fecha seleccionada:', value);
          if (value) {
            setSelectDate({ ...selectDate, date: value });
            setState(moment(value).format('YYYY-MM-DD'));
          }
        }}
        style={{
          minWidth: width, // Aplica el ancho deseado aquí
        }}
      />
    </Application>
  );
};
export default RainCalendar;
