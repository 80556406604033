import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import HtmlHead from 'components/html-head/HtmlHead';
// components
import LoadingCar from 'components/loading/LoadingCar';
import ExpediteDelivery from './components/forms/ExpediteDelivery';
import DamagedPack from './components/forms/DamagedPack';
import ChangeAddress from './components/forms/ChangeAddress';
import ErrDelivery from './components/forms/ErrDelivery';
import LostPack from './components/forms/LostPack';
import ReturnPack from './components/forms/ReturnPack';
import LoadingPulse from '../../components/loading/LoadingPulse';
// reducers
import { setGeneralData } from '../../slices/generalData/generalData';

const NewReport = () => {
  const title = 'Crear Reporte';
  const description = 'Reporte Incidentes Plataforma Autopaquete';
  const { generalData } = useSelector((state) => state.general_data);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isInitLoading, setIsInitLoading] = useState(true);
  const dispatch = useDispatch();

  const title1 = 'Agilizar Entrega';
  const title2 = 'Cambio Domicilio';
  const title3 = 'Entrega Erronea';
  const title4 = 'Paquete Perdido o Percance Externo';
  const title5 = 'Paquete Dañado o Incompleto';
  const title6 = 'Retornar Paquete';
  // const [incidentType, setIncidentType] = useState(title1);
  const [form, setForm] = useState(<ExpediteDelivery />);

  const formController = (incident) => {
    switch (incident) {
      case title1:
        setForm(<ExpediteDelivery />);
        break;
      case title2:
        setForm(<ChangeAddress />);
        break;
      case title3:
        setForm(<ErrDelivery />);
        break;
      case title4:
        setForm(<LostPack />);
        break;
      case title5:
        setForm(<DamagedPack />);
        break;
      case title6:
        setForm(<ReturnPack />);
        break;
      default:
        // enter here at the beginning before it reads the state value
        // ExpediteDelivery I put this to always show something
        <ExpediteDelivery />;
    }
  };
  // const setPage = (incident) => {
  //   setIncidentType(incident);
  // };
  useEffect(() => {
    setIsLoading(true);
    formController(generalData.fields.incidentType);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  // console.log('reporte seleccionado:', generalData);
  useEffect(() => {
    setTimeout(() => {
      setIsInitLoading(false);
    }, 800);
  }, []);
  useEffect(() => {
    return () => {
      // Código a ejecutar al salir de la página
      dispatch(setGeneralData({ data: false, point: 'isVerifiedToReport' }));
    };
  }, []);
  return (
    <>
      {isInitLoading && <LoadingCar />}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container">
          <Row className="g-0">
            {/* Title Start */}
            <Col className="col-auto mb-3 mb-sm-0 me-auto">
              <h1 className="mb-0 pb-0 mt-5 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
          </Row>
        </div>
        {isLoading ? <LoadingPulse /> : form}
      </div>
    </>
  );
};

export default NewReport;
