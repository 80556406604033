import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Row, Col, Button, Card, Form, InputGroup } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
// libraries
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import * as Yup from 'yup';
// utils
import { handleAPIError, handleCatchError } from 'utils/handleErrors';

// components
import LoadingPulse from '../../../../components/loading/LoadingPulse';
import LoadingCar from '../../../../components/loading/LoadingCar';
import { ToastSuccess, ToastErrorCath } from '../../../../components/alerts/toast';
// api
import { createAddress, getZipCode } from '../../../../api/Api-user';

const NewAddress = () => {
  const title = 'Nueva dirección';
  const description = 'Nueva Dirección Plataforma Autopaquete';
  const [addressTypeSend, setAddressTypeSend] = useState(false);
  const [addressTypeReceive, setAddressTypeReceive] = useState(false);

  const history = useHistory();
  // - Use references to do scroll on this page
  const scrollRef = useRef(null);
  // Local State
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingStart, setIsLoadingStart] = useState(true);

  const [address, setAddress] = useState({
    name: '',
    email: '',
    company: '',
    street: '',
    streetNumber: '',
    neighborhood: '',
    zip: '',
    city: '',
    state: '',
    placeRef: '',
    phone: '',
    addressType: '',
  });
  const [addressErr, setAddressErr] = useState({
    streetNumber: false,
    company: false,
    phone: false,
    street: false,
    zip: '',
    email: false,
    placeRef: false,
    neighborhood: false,
    name: false,
    city: false,
    state: false,
    addressType: false,
  });
  // - Schema Validations Start
  const validationAddressesSchema = Yup.object().shape({
    // email, reference, and company are not required
    name: Yup.string().min(3, 'El nombre debe tener al menos 3 caracteres.').required('Por favor, ingresa el nombre.'),
    street: Yup.string().min(3, 'El nombre de la calle debe tener al menos 3 caracteres.').required('Por favor, ingresa el nombre de la calle.'),
    streetNumber: Yup.string().required('Por favor, ingresa el número de la calle.'),
    addressType: Yup.string().required('Por favor, selecciona el tipo de dirección.'),
    neighborhood: Yup.string().min(3, 'El nombre de la colonia debe tener al menos 3 caracteres.').required('Por favor, ingresa el nombre de la colonia.'),
    zip: Yup.string().min(5, 'El código postal debe tener exactamente 5 caracteres.').required('Por favor, ingresa el código postal.'),
    phone: Yup.string().min(10, 'El número de teléfono debe tener exactamente 10 caracteres.').required('Por favor, ingresa un número de teléfono.'),
    email: Yup.string().email('Por favor, ingresa un correo electrónico válido.'),
  });
  // remove accents
  const cleanData = (str) => {
    // Quitar acentos
    let cleanedStr = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    // Eliminar caracteres especiales, dejando solo letras y números
    cleanedStr = cleanedStr.replace(/[^a-zA-Z0-9 ]/g, '');
    return cleanedStr;
  };
  // - Data Validation and Updating States with Reducers
  const updateData = (type, data) => {
    // console.log('valor de data', data, 'en punto:', type);
    if (type === 'phone' || type === 'zip') {
      if (data === '' || /^[0-9]+$/i.test(data)) {
        setAddress({ ...address, [type]: data });
      }
    } else {
      const cleanedData = cleanData(data);
      setAddress({ ...address, [type]: cleanedData });
    }
  };

  const clearForm = () => {
    setAddress({
      name: '',
      email: '',
      company: '',
      street: '',
      streetNumber: '',
      neighborhood: '',
      zip: '',
      city: '',
      state: '',
      placeRef: '',
      phone: '',
      addressType: '',
    });
    // clear checkboxes
    setAddressTypeSend(false);
    setAddressTypeReceive(false);
  };
  // - Create address
  const handlerCreateAddress = async () => {
    try {
      setIsLoading(true);
      const response = await createAddress(address);
      // console.log('response create address:', response);
      if (response.name !== undefined) {
        ToastSuccess('¡Dirección guardada con éxito!');
        clearForm();
        history.push('/configuración/direcciones');
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const validateForm = async (e) => {
    e.preventDefault();
    // - Address  validations
    try {
      await validationAddressesSchema.validate(address, { abortEarly: false });
      if (!addressErr.zip) {
        handlerCreateAddress();
      } else {
        ToastErrorCath('Código postal invalido.');
      }
    } catch (errors) {
      const formErrors = {};
      errors.inner.forEach((error) => {
        // setAddressErr({ ...addressErr, [error.path]: error.message });
        formErrors[error.path] = error.message;
      });
      // console.log('errores en addres from:', formErrors);
      setAddressErr(formErrors);
      // scrollRef.current.scrollIntoView({ behavior: 'smooth' }); // scroll to error
      if (formErrors.email) {
        ToastErrorCath('Por favor, revisa y corrige los campos marcados.');
      } else {
        ToastErrorCath('Por favor, completa todos los campos obligatorios.');
      }
    }
  };
  useEffect(() => {
    // console.log('es direccion destino?', addressTypeSend);
    // console.log('es direccion origen?', addressTypeReceive);
    if (addressTypeSend) {
      setAddress({ ...address, addressType: 'SEND' });
    } else if (addressTypeReceive) {
      setAddress({ ...address, addressType: 'RECEIVE' });
    } else {
      setAddress({ ...address, addressType: '' });
    }
  }, [addressTypeSend, addressTypeReceive]);
  useEffect(() => {
    // - getZipCode received the zip code and makes the request to the microservices and updates the data
    setAddressErr({ ...addressErr, zip: false });
    if (address.zip.length === 5) {
      getZipCode(address.zip)
        .then((response) => {
          if (response.length > 0) {
            setAddress({ ...address, city: response[0].city, state: response[0].state.name });
          } else {
            setAddressErr({ ...addressErr, zip: 'Código invalido' });
          }
        })
        .catch(() => {
          setAddressErr({ ...addressErr, zip: 'Compruebe su conexión a Internet' });
        });
    } else {
      setAddressErr({ ...addressErr, zip: '' });
      setAddress({ ...address, state: '', city: '' });
    }
  }, [address.zip]);
  setTimeout(() => {
    setIsLoadingStart(false);
  }, 500);
  return (
    <>
      {isLoadingStart && <LoadingCar />}
      {isLoading && <LoadingPulse />}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        {/* Title Start */}
        <div className="page-title-container">
          <Col xs="12" lg="10">
            <Row className="g-0 d-flex flex-column flex-sm-row mb-5">
              {/* Title Start  className="col-auto mb-3 mb-sm-0 me-auto" */}
              <Col>
                <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/configuración/direcciones">
                  <CsLineIcons icon="chevron-left" size="13" />
                  <span className="align-middle text-small ms-1">Atrás</span>
                </NavLink>
                <h1 className="mb-0 pb-0 display-4" id="title">
                  {title}
                </h1>
              </Col>
              {/* Title End */}
            </Row>
          </Col>
        </div>
        {/* Title End */}
        <Form onSubmit={validateForm} id="cardContainer">
          <Col xs="12" lg="10">
            {/* Top Buttons Start */}
            <Row className="g-0 d-flex justify-content-end">
              <Col xs="auto">
                <Button variant="outline-primary" className="btn-icon btn-icon-start w-100 w-md-auto" type="submit">
                  <CsLineIcons icon="save" /> <span>Guardar</span>
                </Button>
              </Col>
            </Row>
            {/* Top Buttons End */}
            {/* Personal Info Start */}
            <h2 className="small-title mt-3">Información personal</h2>
            <div className="mb-5" ref={scrollRef}>
              <Card className="mb-2">
                <Card.Body style={{ fontFamily: 'Montserrat' }}>
                  <Row className="g-3">
                    <Col lg="6" className="position-relative">
                      <Form.Label>Nombre</Form.Label>
                      <span className="text-primary">*</span>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="user" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          maxLength={29}
                          placeholder="máximo 29 caracteres"
                          type="text"
                          value={address.name}
                          // style={{ fontFamily: ' Arial,FontAwesome' }}
                          onChange={(e) => {
                            setAddressErr({ ...addressErr, name: false });
                            updateData('name', e.target.value);
                          }}
                        />
                      </InputGroup>
                      {addressErr.name && (
                        <div className={`invalid-tooltip position-absolute ${addressErr.name ? 'd-block' : 'd-none'}`}>{addressErr.name}</div>
                      )}
                    </Col>
                    <Col lg="6">
                      <Form.Label>Empresa</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="building" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          maxLength={29}
                          type="text"
                          placeholder="máximo 29 caracteres"
                          value={address.company}
                          onChange={(e) => {
                            updateData('company', e.target.value);
                          }}
                        />
                      </InputGroup>
                    </Col>
                    <Col sm="4" lg="4">
                      <Form.Label>Telefono</Form.Label>
                      <span className="text-primary">*</span>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="phone" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          maxLength={10}
                          type="text"
                          value={address.phone}
                          placeholder="10 dígitos"
                          onChange={(e) => {
                            setAddressErr({ ...addressErr, phone: false });
                            updateData('phone', e.target.value);
                          }}
                        />
                        {addressErr.phone && <div className={`invalid-tooltip ${addressErr.phone ? 'd-block' : 'd-none'}`}>{addressErr.phone}</div>}
                      </InputGroup>
                    </Col>
                    <Col sm="8" lg="6">
                      <Form.Label>Correo</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="email" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          // maxLength={30}
                          placeholder="name@example.com"
                          type="email"
                          value={address.email}
                          onChange={(e) => {
                            setAddressErr({ ...addressErr, email: false });
                            setAddress({ ...address, email: e.target.value });
                          }}
                        />
                        {addressErr.email && <div className={`invalid-tooltip ${addressErr.email ? 'd-block' : 'd-none'}`}>Ingresa un correo valido</div>}
                      </InputGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
            {/* Personal Info End */}
            {/* Address Detail Start */}
            <h2 className="small-title">Detalles domicilio</h2>
            <div className="mb-5">
              <Card className="mb-2">
                <Card.Body>
                  <Row className="g-3">
                    <Col xs="12" lg="8">
                      <Form.Label>Calle</Form.Label>
                      <span className="text-primary">*</span>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="home" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          maxLength={50}
                          type="text"
                          value={address.street}
                          placeholder="máximo 50 caracteres"
                          onChange={(e) => {
                            setAddressErr({ ...addressErr, street: false });
                            updateData('street', e.target.value);
                          }}
                        />
                        {addressErr.street && <div className={`invalid-tooltip ${addressErr.street ? 'd-block' : 'd-none'}`}>{addressErr.street}</div>}
                      </InputGroup>
                    </Col>
                    <Col sm="3" lg="2" className="position-relative">
                      <Form.Label>Número</Form.Label>
                      <span className="text-primary">*</span>
                      <Form.Control
                        maxLength={10}
                        type="text"
                        placeholder="&#x23;"
                        value={address.streetNumber}
                        onChange={(e) => {
                          setAddressErr({ ...addressErr, streetNumber: false });
                          updateData('streetNumber', e.target.value);
                        }}
                      />
                      {addressErr.streetNumber && (
                        <div className={`invalid-tooltip position-absolute ${addressErr.streetNumber ? 'd-block' : 'd-none'}`}>{addressErr.streetNumber}</div>
                      )}
                    </Col>
                    <Col sm="9" lg="8">
                      <Form.Label>Colonia</Form.Label>
                      <span className="text-primary">*</span>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="home" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          maxLength={35}
                          type="text"
                          value={address.neighborhood}
                          placeholder="máximo 35 caracteres"
                          onChange={(e) => {
                            setAddressErr({ ...addressErr, neighborhood: false });
                            updateData('neighborhood', e.target.value);
                          }}
                        />
                        {addressErr.neighborhood && (
                          <div className={`invalid-tooltip ${addressErr.neighborhood ? 'd-block' : 'd-none'}`}>{addressErr.neighborhood}</div>
                        )}
                      </InputGroup>
                    </Col>
                    <Col sm="3" lg="2">
                      <Form.Label>Código postal</Form.Label>
                      <span className="text-primary">*</span>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="pin" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          maxLength={5}
                          type="text"
                          value={address.zip}
                          onChange={(e) => {
                            updateData('zip', e.target.value);
                          }}
                        />
                        {addressErr.zip && <div className={`invalid-tooltip ${addressErr.zip ? 'd-block' : 'd-none'}`}>{addressErr.zip}</div>}
                      </InputGroup>
                    </Col>
                    <Col sm="4" lg="3">
                      <Form.Label>Ciudad</Form.Label>
                      <Form.Control type="text" readOnly value={address.city} />
                    </Col>
                    <Col sm="5" lg="3">
                      <Form.Label>Estado</Form.Label>
                      <Form.Control type="text" readOnly value={address.state} />
                    </Col>
                    <Col lg="6" xxl="6">
                      <Form.Label>Referencias</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="destination" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          maxLength={20}
                          placeholder="máximo 20 caracteres"
                          type="text"
                          value={address.placeRef}
                          onChange={(e) => {
                            updateData('placeRef', e.target.value);
                          }}
                        />
                      </InputGroup>
                    </Col>
                    {/* className="bg-success" */}
                    <Col>
                      <Form.Label>Tipo de dirección</Form.Label>
                      <span className="text-primary">*</span>
                      <div style={{ position: 'relative', zIndex: 1, maxWidth: '200px' }}>
                        <div className="ms-2">
                          {/* address from */}
                          <input
                            type="checkbox"
                            className="form-check-input me-2"
                            checked={addressTypeSend}
                            onClick={() => {
                              setAddressTypeSend(!addressTypeSend);
                              setAddressErr({ ...addressErr, addressType: false });
                              setAddressTypeReceive(false);
                            }}
                          />
                          <label className="form-label">Origen</label>
                        </div>
                        <div className="ms-2">
                          {/* address to */}
                          <input
                            type="checkbox"
                            className="form-check-input me-2"
                            checked={addressTypeReceive}
                            onClick={() => {
                              setAddressTypeReceive(!addressTypeReceive);
                              setAddressErr({ ...addressErr, addressType: false });
                              setAddressTypeSend(false);
                            }}
                          />
                          <label className="form-label">Destino</label>
                        </div>
                        {addressErr.addressType && (
                          <div className={`invalid-tooltip ${addressErr.addressType ? 'd-block' : 'd-none'}`}>{addressErr.addressType}</div>
                        )}
                      </div>
                    </Col>
                    {/* className="bg-secondary" */}
                  </Row>
                </Card.Body>
              </Card>
            </div>
            {/* Address Detail End */}
          </Col>
        </Form>
      </div>
    </>
  );
};

export default NewAddress;
