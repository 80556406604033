import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import Invoices from './invoices/Invoices';
import Receipts from './receipts/Receipts';
import Reps from './reps/Reps';
// import Loading from '../../../../components/loading/LoadingRed';

const BillingAdmin = () => {
  const title = 'Facturación';
  const { crmUserId, userName } = useParams();
  const description = 'Facturaión Plataforma Autopaquete';
  const history = useHistory();
  const [billingHistory, setBillingHistory] = useState(<Invoices crmId={crmUserId} />);

  function submenuActive(e) {
    const submenuBtns = document.querySelectorAll('ul.nav-pills li button');
    const currentBtn = e.currentTarget.name;
    submenuBtns.forEach((btn) => {
      if (currentBtn === btn.name) {
        btn.classList.add('active');
      } else {
        btn.classList.remove('active');
      }
    });
  }
  const resetInfo = (e) => {
    const type = e.target.name;
    submenuActive(e);
    switch (type) {
      case 'invoice':
        setBillingHistory(<Invoices crmId={crmUserId} />);
        break;
      case 'rep':
        setBillingHistory(<Reps crmId={crmUserId} />);
        break;
      case 'receipts':
        setBillingHistory(<Receipts crmId={crmUserId} />);
        break;
      default:
        setBillingHistory(<Invoices crmId={crmUserId} />);
    }
  };

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-title-container">
        <Row className="g-0">
          {/* Title Start */}
          <Col className="col-auto mb-3 mb-sm-0 me-auto">
            <div className="muted-link pb-1 d-inline-block hidden breadcrumb-back cursor-pointer" onClick={() => history.goBack()}>
              <CsLineIcons icon="chevron-left" size="13" />
              <span className="align-middle text-small ms-1">Atrás</span>
            </div>
            <h1 className="mb-0 pb-0 display-4" id="title">
              {title}
            </h1>
          </Col>
          {/* Title End */}
        </Row>
      </div>
      <h2 className="cta-1 text-primary mb-5 text-center text-sm-start">{userName}</h2>
      {/* Submenu horizontal */}
      <Row className="g-0 mb-5">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <button type="button" className="nav-link active" name="invoice" onClick={(e) => resetInfo(e)}>
              Facturas
            </button>
          </li>
          <li className="nav-item">
            <button type="button" className="nav-link" name="receipts" onClick={(e) => resetInfo(e)}>
              Recibos
            </button>
          </li>
          <li className="nav-item">
            <button type="button" className="nav-link" name="rep" onClick={(e) => resetInfo(e)}>
              REP'S
            </button>
          </li>
        </ul>
      </Row>
      <div>{billingHistory}</div>
    </>
  );
};

export default BillingAdmin;
