import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  generalData: {
    fields: {
      incidentType: false,
      isVerifiedToReport: false,
    },
  },
};
const generalDataSlice = createSlice({
  name: 'generalData',
  initialState,
  reducers: {
    setGeneralData(state, action) {
      const { point, data } = action.payload;
      state.generalData.fields[point] = data;
    },
  },
});
export const { setGeneralData } = generalDataSlice.actions; // imported in file.js
const generalDataReducer = generalDataSlice.reducer; // store.js setup
export default generalDataReducer;
