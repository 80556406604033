import React, { useState, useEffect } from 'react';
import { useHistory, useParams, NavLink } from 'react-router-dom';
import { Row, Col, Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import moment from 'moment';
import 'moment/locale/es'; // Importa el idioma español

// utils
import { initialsUpperCase, startUpperCase } from 'utils/textFormat';
import { handleAPIError, handleCatchError } from 'utils/handleErrors';
// components
import LoadingCar from '../../components/loading/LoadingCar';
// api
import { getTicketDetail } from '../../api/Api-user';

const ReportDetail = () => {
  const title = 'Detalle reporte';
  const description = 'Detalle Reporte Plataforma Autopaquete';
  const history = useHistory();
  moment.locale('es'); // Configura moment a español

  // Local State
  const { ticketId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [ticket, setTicket] = useState(true);
  let statusGeneral;
  let updateTime;
  let fontStatus;
  let typeDate;
  const handleTicketDetail = async () => {
    try {
      const response = await getTicketDetail(ticketId);
      // console.log('response detalle ticket:', response);
      if (response.id) {
        setIsLoading(false);
        setTicket(response);
      }
    } catch (error) {
      history.push('/reportes/historial');
      // handleCatchError(error);
    }
  };

  switch (ticket.statusType) {
    case 'Closed':
      statusGeneral = 'Cerrado';
      updateTime = ticket.closedTime;
      fontStatus = '#6c757d';
      typeDate = 'Fecha de cierre';
      break;
    case 'Open':
      statusGeneral = 'Abierto';
      // updateTime = ticket.modified_time;
      updateTime = ticket.modifiedTime;
      fontStatus = '#28a745';
      typeDate = 'Fecha de ultima actualización';
      break;
    case 'On Hold':
      statusGeneral = 'En pausa';
      updateTime = ticket.onholdTime;
      fontStatus = '#fd7e14';
      typeDate = 'Fecha de ultima actualización';

      break;
    default:
      statusGeneral = 'error';
      updateTime = ticket.createdTime;
  }
  useEffect(() => {
    handleTicketDetail();
  }, []);
  // fecha de ultima actualizacion modifiedTime
  // status general
  // descripcion del status
  // imagen de asesor
  // email
  // informacion del formulario, direccion origen o destino y paquete
  // console.log('comentarios:', ticket.comments);

  return (
    <>
      {isLoading && <LoadingCar />}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container">
          <Row className="g-0">
            {/* Title Start */}
            <Col className="col-auto mb-3 mb-sm-0 me-auto">
              <div className="muted-link pb-1 d-inline-block hidden breadcrumb-back cursor-pointer" onClick={() => history.goBack()}>
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Atrás</span>
              </div>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
            {/* Top Buttons Start */}
            {/* <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3 bg-info">
              -
            </Col> */}
            {/* Top Buttons End */}
          </Row>
        </div>
        <Row>
          <Col xl="8">
            {/* init Start */}
            <h2 className="small-title invisible">Reporte detalles</h2>
            <Row className="g-2 mb-5">
              <Col sm="6">
                <Card className="sh-13 sh-lg-15 sh-xl-14">
                  <Card.Body className="h-100 py-3 d-flex align-items-center">
                    <Row className="g-0 align-items-center">
                      <Col xs="auto" className="pe-3">
                        <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                          <CsLineIcons icon="support" className="text-primary" />
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex align-items-center lh-1-25">Reporte</div>
                        <div className="text-primary">{ticket.cf?.cf_incidencia}</div>
                        {/* <div className="text-primary">{typeof ticket.subject === 'string' && ticket.subject.split('-')[0]}</div> */}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6">
                <Card className="sh-13 sh-lg-15 sh-xl-14">
                  <Card.Body className="h-100 py-3 d-flex align-items-center">
                    <Row className="g-0 align-items-center">
                      <Col xs="auto" className="pe-3">
                        <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                          <CsLineIcons icon="calendar" className="text-primary" />
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex align-items-center lh-1-25">Fecha Creación</div>
                        <div className="text-primary">{moment(ticket.createdTime).format('DD-MM-YYYY HH:mm')}</div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6">
                <Card className="sh-13 sh-lg-15 sh-xl-14">
                  <Card.Body className="h-100 py-3 d-flex align-items-center">
                    <Row className="g-0 align-items-center">
                      <Col xs="auto" className="pe-3">
                        <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                          <CsLineIcons icon="shipping" className="text-primary" />
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex align-items-center lh-1-25">ID Envío</div>
                        <OverlayTrigger delay={{ show: 100, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Ver detalle de envío</Tooltip>}>
                          <NavLink to={`/envios/historial/detalle/${ticket.cf?.cf_numero_de_guia || ''}`}>{ticket.cf?.cf_numero_de_guia}</NavLink>
                        </OverlayTrigger>
                        {/* <div className="text-primary">{typeof ticket.subject === 'string' && ticket.subject.split('-')[1]}</div> */}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6">
                <Card className="sh-13 sh-lg-15 sh-xl-14">
                  <Card.Body className="h-100 py-3 d-flex align-items-center">
                    <Row className="g-0 align-items-center">
                      <Col xs="auto" className="pe-3">
                        <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                          <CsLineIcons icon="clipboard" className="text-primary" />
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex align-items-center lh-1-25">ID Reporte</div>
                        <div className="text-primary">{ticket.ticketNumber}</div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {/* init  End */}
            <h2 className="small-title">Estado</h2>
            <Card className="mb-5">
              <Card.Body className="h-100 py-3 d-flex align-items-center">
                <Row>
                  <Col xs="12" className="bg-infox mb-3">
                    {/* <Button variant="link" className="p-0 pt-1 heading text-start">
                      {`${typeDate} ${moment.utc(updateTime).local().format('DD-MM-YYYY HH:mm')}`}
                    </Button> */}
                    <div className={`text-alternate d-block bg-infox  ${ticket?.comments?.length === 0 && 'd-none'}`}>{`${typeDate} ${moment
                      .utc(updateTime)
                      .local()
                      .format('dddd, DD [de] MMMM [de] YYYY HH:mm')}`}</div>
                  </Col>
                  <Col xs="12" className="bg-successx mb-3">
                    <div style={{ background: fontStatus }} className="tag-label">
                      {statusGeneral}
                    </div>
                    {ticket.statusType === 'Closed' && (
                      <div style={{ background: ticket.status === 'Solución Desfavorable' ? '#FF8C42' : '#28a745' }} className="tag-label ms-5">
                        {ticket.status}
                      </div>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {/* <h2 className={`small-title ${ticket?.comments?.length === 0 && 'd-none'}`}>Actualizaciones</h2> */}
            <h2 className="small-title">Actualizaciones</h2>

            <Card className="mb-5" style={{ minHeight: '122px' }}>
              <Card.Body>
                {ticket?.comments?.length === 0 ? (
                  <div>Cuando haya actualizaciones sobre el seguimiento de tu reporte, podrás verlas aquí</div>
                ) : (
                  ticket?.comments
                    ?.slice()
                    .reverse()
                    .map((status, index) => (
                      <Row className="g-0 mb-4" key={index}>
                        <Col xs="auto" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-4 bg-successx">
                          <div className="w-100 d-flex sh-1" />
                          <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                            <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                          </div>
                          <div className="w-100 d-flex h-100 justify-content-center position-relative">
                            <div className="line-w-1 bg-separator h-100 position-absolute" />
                          </div>
                        </Col>
                        <Col className="bg-infox">
                          <div className="h-100">
                            <div className="d-flex flex-column justify-content-start">
                              <div className="d-flex flex-column">
                                <div className="text-primary">{moment(status.commentedTime).format('dddd, DD [de] MMMM [de] YYYY HH:mm')}</div>
                                <div className="text-alternate mt-1 pre-wrap">{status.plainText}</div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    ))
                )}
              </Card.Body>
            </Card>

            {/* Status End */}
          </Col>
          {/* Address Start */}
          <Col xl="4">
            {/* Address Start */}
            <h2 className="small-title">Reporte</h2>
            <Card className="mb-5">
              <Card.Body>
                <div className="mb-5">
                  <p className="text-small text-muted mb-2">EMAIL DE CONTACTO</p>
                  <Row className="g-0 mb-2">
                    <Col xs="auto">
                      <div className="sw-3 me-1">
                        <CsLineIcons icon="email" size="17" className="text-primary" />
                      </div>
                    </Col>
                    <Col className="text-alternate">{ticket.contact?.email}</Col>
                  </Row>
                </div>
                <div className="mb-5">
                  <p className="text-small text-muted mb-2">{`DIRECCIÓN ${ticket.category !== 'Retornar Paquete' ? 'DESTINO' : 'ORIGEN'}`}</p>
                  <Row className={`g-0 mb-2 ${ticket.category === 'Retornar Paquete' && 'd-none'}`}>
                    <Col xs="auto">
                      <div className="sw-3 me-1">
                        <CsLineIcons icon="home" size="17" className="text-primary" />
                      </div>
                    </Col>
                    <Col className="text-alternate">{initialsUpperCase(ticket.cf?.cf_calle_y_numero_destino || '')}</Col>
                  </Row>
                  <Row className={`g-0 mb-2 ${ticket.category === 'Retornar Paquete' && 'd-none'}`}>
                    <Col xs="auto">
                      <div className="sw-3 me-1">
                        <CsLineIcons icon="home" size="17" className="text-primary" />
                      </div>
                    </Col>
                    <Col className="text-alternate">{startUpperCase(ticket.cf?.cf_colonia_destino || '')}</Col>
                  </Row>
                  <Row className={`g-0 mb-2 ${ticket.category === 'Retornar Paquete' && 'd-none'}`}>
                    <Col xs="auto">
                      <div className="sw-3 me-1">
                        <CsLineIcons icon="pin" size="17" className="text-primary" />
                      </div>
                    </Col>
                    <Col className="text-alternate">
                      {`${startUpperCase(ticket.cf?.cf_ciudad_destino || '')}, ${startUpperCase(ticket.cf?.cf_estado_destino)}, ${
                        ticket.cf?.cf_cp_destino || ''
                      }`}
                    </Col>
                  </Row>
                  <Row className="g-0 mb-2">
                    <Col xs="auto">
                      <div className="sw-3 me-1">
                        <CsLineIcons icon="minimize" size="17" className="text-primary" />
                      </div>
                    </Col>
                    <Col className="text-alternate">
                      {startUpperCase(ticket.category === 'Retornar Paquete' ? ticket.cf?.cf_entre_calles_origen : ticket.cf?.cf_entre_calles_destino)}
                    </Col>
                  </Row>
                  <Row className={`g-0 mb-2 ${ticket.category === 'Retornar Paquete' && 'd-none'}`}>
                    <Col xs="auto">
                      <div className="sw-3 me-1">
                        <CsLineIcons icon="home-garage" size="17" className="text-primary" />
                      </div>
                    </Col>
                    <Col className="text-alternate">{startUpperCase(ticket.cf?.cf_color_fachada_destino || '')}</Col>
                  </Row>
                  <Row className="g-0 mb-2">
                    <Col xs="auto">
                      <div className="sw-3 me-1">
                        <CsLineIcons icon="destination" size="17" className="text-primary" />
                      </div>
                    </Col>
                    <Col className="text-alternate">
                      {startUpperCase(
                        ticket.category === 'Retornar Paquete' ? ticket.cf?.cf_referencias_origen || '' : ticket.cf?.cf_referencias_destino || ''
                      )}
                    </Col>
                  </Row>
                  <Row className={`g-0 mb-2 ${ticket.category === 'Retornar Paquete' && 'd-none'}`}>
                    <Col xs="auto">
                      <div className="sw-3 me-1">
                        <CsLineIcons icon="phone" size="17" className="text-primary" />
                      </div>
                    </Col>
                    <Col className="text-alternate">{ticket.cf?.cf_telefono_destino || ''}</Col>
                  </Row>
                </div>
                <div className="mb-5">
                  <p className="text-small text-muted mb-2">PAQUETE</p>
                  <Row className="g-0 mb-2">
                    <Col xs="auto">
                      <div className="sw-3 me-1">
                        <CsLineIcons icon="edit-square" size="17" className="text-primary" />
                      </div>
                    </Col>
                    <Col className="text-alternate">{startUpperCase(ticket.cf?.cf_contenido_del_paquete)}</Col>
                  </Row>
                  <Row className="g-0 mb-2">
                    <Col xs="auto">
                      <div className="sw-3 me-1">
                        <CsLineIcons icon="box" size="17" className="text-primary" />
                      </div>
                    </Col>
                    <Col className="text-alternate">{startUpperCase(ticket.cf?.cf_descripcion_del_embalaje)}</Col>
                  </Row>
                </div>
                {ticket.cf?.cf_comentarios_adicionales_del_cliente !== null && (
                  <div className="mb-5">
                    <p className="text-small text-muted mb-2">COMENTARIOS</p>
                    <Row className="g-0 mb-2">
                      <Col className="text-alternate">{startUpperCase(ticket.cf?.cf_comentarios_adicionales_del_cliente)}</Col>
                    </Row>
                  </div>
                )}
              </Card.Body>
            </Card>
            {/* Address End */}
          </Col>
          {/* Address End */}
        </Row>
      </div>
    </>
  );
};

export default ReportDetail;
