import React from 'react';
import { Card } from 'react-bootstrap';

const WarningModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null; // No renderiza el modal si está cerrado

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          position: 'relative',
          width: '90%',
          maxWidth: '600px',
          backgroundColor: '#fff',
          borderRadius: '8px',
          overflow: 'hidden',
        }}
      >
        <Card>
          <Card.Body>
            {/* Botón para cerrar */}
            <button
              onClick={onClose}
              type="button"
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                backgroundColor: 'transparent',
                border: 'none',
                color: '#000',
                fontSize: '1.5rem',
                cursor: 'pointer',
              }}
            >
              &times;
            </button>
            {/* <h2>Aviso Importante ! </h2> */}
            <div>
              <img src="./img/animation/warningAdvice.webp" alt="aviso" width="100%" />
            </div>
            <div />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};
export default WarningModal;
