import React from 'react';
import { Carousel } from 'react-bootstrap';

const NotificationGallery = ({ notifications }) => {
  return (
    <>
      <style>
        {`
          .carousel-control-prev-icon,
          .carousel-control-next-icon {
            background-color: #00000080;
            border-radius: 50%;
            width: 40px;
            height: 40px;
          }
        
          .carousel-control-prev-icon::before,
          .carousel-control-next-icon::before {
           padding: 2px;
            color: #000;
            font-size: 1.0rem;
          }
        `}
      </style>
      <Carousel className="bg-alternatex shadow-sm rounded m-auto overflow-hidden m-0" style={{ maxWidth: '650px', maxHeight: '510px' }}>
        {Array.isArray(notifications) && notifications.length > 0 ? (
          notifications.map((notice, index) => (
            <Carousel.Item key={notice.id || index} interval={4000} className="bg-primaryx p-0 rounded">
              <img src={notice.img} alt={`Image ${index + 1}`} width="100%" className="m-auto rounded" />
              {/* <Carousel.Caption className="bg-infox">
          <h3 className="bg-alternate">{notice.title}</h3>
          <p>{notice.message_body}</p>
        </Carousel.Caption> */}
            </Carousel.Item>
          ))
        ) : (
          <Carousel.Item className="bg-primaryx p-0 rounded">
            <img src="public/img/autopaquete/noticeDefault.png" alt="Notificación por defecto" width="100%" className="m-auto rounded" />
          </Carousel.Item>
        )}
      </Carousel>
    </>
  );
};

export default NotificationGallery;
