import { apiV1AxiosInstanceLogin, apiV1AxiosInstance, msZipCodeAxiosInstance, apiV1AxiosInstanceForm } from './http';
import { toUpperIfString } from '../utils/textFormat';
// Base URL:https://api.autopaquete.com.mx/v1

const login = (email, password) =>
  apiV1AxiosInstanceLogin('/user-login', {
    method: 'POST',
    data: {
      user_email: email,
      password,
    },
  })
    .then((response) => {
      // console.log(response);
      return response.data;
    })
    .catch((error) => {
      // console.log('login error', error.response);

      return error.response.data;
    });

// ****************  GETS  ******************************
const getCounters = () =>
  apiV1AxiosInstance('/home-counters/', { method: 'GET' })
    .then((response) => {
      // console.log('-------- getCounter response ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getCounter catch err ---------->', error.response.data);
      return error.response.data;
    });

const getZipCode = (cp) =>
  msZipCodeAxiosInstance(`/${cp}/`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
const getRates = (data) =>
  apiV1AxiosInstance(`/rates`, {
    method: 'POST',
    timeout: 20000,
    data: {
      zip_from: data.zipFrom,
      zip_to: data.zipTo,
      parcel: {
        height: data.height,
        width: data.width,
        depth: data.depth,
        weight: data.weight,
        product_value: data.productValue ? data.productValue : 0,
        multi_pieces: data.multi_pieces,
      },
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getRates catch err ---------->', error.response.data);
      //  error.response.data = {error:[], error_code:""}
      // error.response = { config: , data:{error:[], error_code:""} , headers: , request: ,status: ,statusText: , etc..}
      if (error.response) {
        return error.response.data;
      }
      throw error;
    });
// Shippings
const getShippingHistory = async (num, limit) =>
  apiV1AxiosInstance(`/shippings/list/?page=${num}&page_size=${limit}`, {
    method: 'GET',
    timeout: 35000,
  })
    .then((response) => {
      // console.log('-------- getShippingHistory response ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getShippingHistory catch err ---------->', error);
      return error.response.data;
    });
const getShippingDetails = (idLabel) =>
  apiV1AxiosInstance(`/shippings/detail/${idLabel}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getShippingDetails response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getShippingDetails catch err ---------->', error.response);
      return error.response.data;
    });
const getFilteredHistory = (currentPage, pageSize, identifier, serviceName, startDate, endDate) =>
  apiV1AxiosInstance(
    `/shippings/list/?term=${identifier}&shipping_company=${serviceName}&date=${startDate}&end_date=${endDate}&page=${currentPage}&page_size=${pageSize}`,
    {
      method: 'GET',
    }
  )
    .then((response) => {
      // console.log('-------- getFilteredHistory response ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getFilteredHistory catch err ---------->', error.response);
      return error.response.data;
    });
// Directories
// are used in the shipping form to filter by name without pagination
// eliminarlos y usar getFilteredAddress y getFilteredParcels
const getAddressesDirectory = (string) =>
  apiV1AxiosInstance(`/addresses/list/?name=${string}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getAddressesDirectory response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getAddressesDirectory catch err ---------->', error.response);
      return error;
    });
const getParcelDirectory = (string) =>
  apiV1AxiosInstance(`/parcels/list/?name=${string}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getParcelDirectory response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getParcelDirectory catch err ---------->', error.response.data);
      return error;
    });
// Statements
const getStatementsHistory = (num, limit) =>
  // name_concept end_date  date
  apiV1AxiosInstance(`/statements/list/?page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      console.log('-------- getMovementsHistory response ---------->', response);

      return response.data;
    })
    .catch((error) => {
      console.log('-------- getMovementsHistory catch err ---------->', error.response.data);
      return error.response.data;
    });
const getStatementsFiltered = (num, limit, concept, startDate, endDate) =>
  // name_concept end_date  date
  apiV1AxiosInstance(`/statements/list/?page=${num}&page_size=${limit}&date=${startDate}&end_date=${endDate}&name_concept=${concept}`, {
    method: 'GET',
  })
    .then((response) => {
      console.log('-------- getStatementsFiltered response ---------->', response);

      return response.data;
    })
    .catch((error) => {
      console.log('-------- getStatementsFiltered catch err ---------->', error.response.data);
      return error.response.data;
    });
// Overweight
const getOverweightHistory = (num, limit) =>
  apiV1AxiosInstance(`/overweights/list/?page=${num}&page_size=${limit}`, {
    method: 'GET',
    timeout: 35000,
  })
    .then((response) => {
      // console.log('-------- getOverweightHistory res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getOverweightHistory catch err ---------->', error.response.data);
      return error.response.data;
    });
// Pickups
const getPickups = (num, limit) =>
  apiV1AxiosInstance(`pickups/list/?page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getPickups response ---------->', response);

      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getPickups catch err ---------->', error.response.data);
      return error.response.data;
    });
// Orders
const getOrders = (num, limit) =>
  apiV1AxiosInstance(`/orders/list/?page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getOrders catch err ---------->', error.response);
      return error.response.data;
    });
const getFilteredOrders = (id, startDate, endDate, num, limit) =>
  apiV1AxiosInstance(`/orders/list/?order_id=${id}&date=${startDate}&end_date=${endDate}&page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getFilteredOrders response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getFilteredOrders catch err ---------->', error.response);
      return error.response.data;
    });
const getOrderById = (orderId) =>
  // orders/detail/str:id
  apiV1AxiosInstance(`/orders/detail/${orderId}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getOrderDetail response  ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getOrderDetail catch err ---------->', error.response);
      return error.response.data;
    });
const getShippingPDF = (id) =>
  apiV1AxiosInstance(`orders/pdf/?shipping_id=${id}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getShippingPdf ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getShippingPdf catch err ---------->', error.response);
      return error.response.data;
    });
const getOrderZip = (orderId) =>
  apiV1AxiosInstance(`/orders/zip/?order_id=${orderId}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getOrderZip response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getOrderZip catch err ---------->', error.response);
      return error.response.data;
    });
// Addresses
const getAddresses = (num, limit) =>
  // /address?
  apiV1AxiosInstance(`/addresses/list/?page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getAddresses response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getAddresses catch err ---------->', error.response.data);
      return error.response.data;
    });
const getAddressesDetail = (id) =>
  // /addresses/detail/str:id
  apiV1AxiosInstance(`/addresses/detail/${id}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getAddressesDetail response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getAddressesDetail catch err ---------->', error.response.data);
      return error.response.data;
    });
const getFilteredAddress = (name, num, limit) =>
  apiV1AxiosInstance(`/addresses/list/?name=${name}&page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getFilteredAddress response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getFilteredAddress catch err ---------->', error.response.data);
      return error.response.data;
    });
// Parcels
const getParcels = (num, limit) =>
  apiV1AxiosInstance(`/parcels/list/?page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getParcel response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getParcel catch err ---------->', error.response);
      return error.response.data;
    });
const getFilteredParcels = (name, num, limit) =>
  apiV1AxiosInstance(`/parcels/list/?name=${name}&page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getFilteredParcels response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getFilteredParcels catch err ---------->', error.response.data);
      return error.response.data;
    });
// Support
const getSupportTickets = (num, limit) =>
  apiV1AxiosInstance(`/tickets/soporte?page=${num}&page_size=${limit}&included_modified_time=True`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getSupportTickets response ---------->', response.data);
      return response.data;
    })

    .catch((error) => {
      // console.log('-------- getSupportTickets updated catch err ---------->', error.response);
      // Si hay error.response, estructura el error similar a una respuesta normal
      if (error.response) {
        // console.log('status code http:', error.response.status); // Muestra el código de estado del error
        return error.response.data; // Pasar al `then` unificado
      }
      throw error; // Si no hay respuesta, lanzar para el `catch` final
    });
const getTicketDetail = (id) =>
  apiV1AxiosInstance(`/tickets/details/${id}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getTicketDetail response ---------->', response.data);
      return response.data;
    })

    .catch((error) => {
      if (error.response) {
        // console.log('getTicketDetail catch:', error.response.data);
        return error.response.data;
      }
      throw error; // Si no hay respuesta, lanzar para el `catch` final
    });
const getResourcesForm = () =>
  apiV1AxiosInstance('/incidences/', {
    method: 'GET',
  })
    .then((response) => {
      // console.log('--------getResourcesForm response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('--------getResourcesForm  catch err ---------->', error.response);
      return error.response;
    });
// Get shipping companies
const getAllServices = () =>
  apiV1AxiosInstance('/carrier-data/shipping-company', {
    method: 'GET',
  })
    .then((response) => {
      // console.log('--------getAllServices response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('--------getAllServices  catch err ---------->', error.response);
      return error.response.data;
    });
// get available services list
const getAvailableServices = () =>
  apiV1AxiosInstance('/available-services', {
    method: 'GET',
  })
    .then((response) => {
      // console.log('--------getAvailableServices response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('--------getAvailableServices  catch err ---------->', error.response.data);
      return error.response.data;
    });
// /admin-invoices
// /admin-receipts
// /admin-reps
const getDocuments = (docType, page, limit) =>
  // /v1/invoices?page=1&page_size=10
  apiV1AxiosInstance(`/${docType}?page=${page}&page_size=${limit}`, {
    method: 'GET',
    timeout: 35000,
  })
    .then((response) => {
      // console.log('-------- getDocuments response ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getDocuments catch err ---------->', error.response.data);
      return error.response.data;
    });

const getShipment = (idDoc) =>
  // * Start Detalle de envio Page id = id del documento *
  apiV1AxiosInstance(`/shippings/${idDoc}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getShipment catch err ---------->', error.response);
      return error;
    });
const getChargeConcepts = () =>
  apiV1AxiosInstance('/admin-additional-charges', {
    method: 'GET',
  })
    .then((response) => {
      console.log('-------- getChargeConcepts response ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      console.log('-------- getChargeConcepts catch err ---------->', error.response);
      return error.response;
    });
// ****************** POSTS ********************************

const createShipping = (shippingCode, addressFrom, addressTo, parcel) =>
  apiV1AxiosInstance('/shippings', {
    method: 'POST',
    data: {
      shipping_code: shippingCode,
      address_from: addressFrom,
      address_to: addressTo,
      parcel,
    },
    timeout: 35000,
  })
    .then((response) => {
      // console.log('-------- createShipping PRUEBAS---------->', response);
      // return response.data;
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- createShipping catch err ---------->', error);
      //  error.response.data = {error:[], error_code:""}
      // error.response = { config: , data:{error:[], error_code:""} , headers: , request: ,status: ,statusText: , etc..}
      return error.response.data;
    });
const cancelShipping = (shippingID, requestReason) =>
  apiV1AxiosInstance('/shipping-cancelation', {
    method: 'POST',
    data: {
      shipping_id: shippingID,
      reason: requestReason,
    },
    timeout: 35000,
  })
    .then((response) => {
      // console.log('-------- cancelShipping response ---------->', response);
      // return response.data;
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- cancelShipping catch err ---------->', error);
      //  error.response.data = {error:[], error_code:""}
      // error.response = { config: , data:{error:[], error_code:""} , headers: , request: ,status: ,statusText: , etc..}
      return error.response.data;
    });
const createAddress = (newAddress) =>
  // addresses/create/ nuevo
  // /address anterior
  apiV1AxiosInstance('/addresses/create/', {
    method: 'POST',
    data: {
      street_number: newAddress.streetNumber,
      company: newAddress.company, // opcional
      phone: newAddress.phone,
      street: newAddress.street,
      zip_code: newAddress.zip,
      email: newAddress.email, // opcional
      reference: newAddress.placeRef, // opcional
      neighborhood: newAddress.neighborhood,
      name: newAddress.name,
      address_type: newAddress.addressType,
    },
  })
    .then((response) => {
      // console.log('-------- createAddress response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- createAddress catch err ---------->', error.response.data);
      return error.response.data;
    });
const createParcel = (parcel) =>
  apiV1AxiosInstance('/parcels/create/', {
    method: 'POST',
    data: {
      name: parcel.name,
      product_description: parcel.description,
      height: parcel.height,
      width: parcel.width,
      depth: parcel.depth,
      weight: parcel.weight,
      product_value: parcel.value ? parcel.value : 0,
      insurance: parcel.insurance,
    },
  })
    .then((response) => {
      // console.log('-------- createParcel response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- createParcel catch err ---------->', error.response.data);
      return error.response.data;
    });
const createPickup = (pickup) =>
  apiV1AxiosInstance('/pickups', {
    method: 'POST',
    data: {
      shipping_code: pickup.shipping_code,
      address_from: {
        company: pickup.company,
        name: pickup.name,
        phone: pickup.phone,
        street: pickup.street,
        street_number: pickup.street_number,
        email: pickup.email,
        neighborhood: pickup.neighborhood,
        zip_code: pickup.zip_code,
        reference: pickup.reference,
        state: pickup.state,
        rfc: pickup.rfc,
        city: pickup.city,
      },
      shipping_id: pickup.shipping_id,
      total_packages: pickup.total_packages,
      total_weight: pickup.total_weight,
      pickup_time: pickup.pickup_time,
      pickup_date: pickup.pickup_date,
      side_streets: pickup.side_streets,
    },
  })
    .then((response) => {
      // console.log('-------- createPickup res ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- createPickup catch err ---------->', error.response);
      return error.response.data;
    });
const createOrder = (formData) =>
  apiV1AxiosInstance('/bulk_shippings', {
    method: 'POST',
    data: formData,
  })
    .then((response) => {
      console.log('-------- createOrder responser ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      console.log('-------- createOrder catch err ---------->', error.response.data);
      return error.response.data;
    });
/// tampoco se usa ???
const getOrderExcel = (type) =>
  apiV1AxiosInstance('/download-format', {
    method: 'POST',
    data: {
      code_concept: type,
    },
  })
    .then((response) => {
      //  console.log('-------- getOrderExcel res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getOrderExcel catch err ---------->', error.response.data);
      return error.response;
    });
const createSupportTicket = async (data) => {
  // console.log('-------- data  ---------->', data);
  const productDetails = `6.REPARAR?: ${toUpperIfString(data.isRepairable) ?? ''}| 7.COSTO: ${toUpperIfString(data.repairCost) ?? ''}| 8.CINTA?: ${
    toUpperIfString(data.packageTape) ?? ''
  }`;
  const productDescription = `2.DAÑO: ${toUpperIfString(data.packagingInternalDescription) ?? ''}| 4.PZAS: ${
    toUpperIfString(data.missingParts) ?? '0'
  }| 5.COINCIDE?: ${toUpperIfString(data.damagedDescription) ?? ''} `;
  // Create an object FormData
  const formData = new FormData();
  // Add form's fields to FormData
  formData.append('shipping_id', data.shippingId);
  formData.append('incident_type', data.incidentType);
  formData.append('shipping_company', data.serviceName);
  formData.append('insurance_policy', data.isInsurance ? 'Si' : 'No');
  formData.append('to_street', `${toUpperIfString(data.street ?? '')} ${data.streetNumber ?? ''}`);
  formData.append('to_neighborhood', toUpperIfString(data.neighborhood ?? ''));
  formData.append('to_zip_code', data.cp ?? '');
  formData.append('to_city', toUpperIfString(data.city ?? ''));
  formData.append('to_state', toUpperIfString(data.state ?? ''));
  formData.append('to_references', toUpperIfString(data.references ?? ''));
  formData.append('to_phone', data.phoneTo ?? '');
  formData.append('email', data.email ?? '');
  formData.append('to_sidestreets', toUpperIfString(data.betweenStreet ?? ''));
  formData.append('to_facade_color', toUpperIfString(data.facade ?? ''));
  formData.append('from_sidestreets', toUpperIfString(data.betweenStreetFrom ?? ''));
  formData.append('from_references', toUpperIfString(data.referencesFrom ?? ''));
  // info package
  formData.append('package_content', toUpperIfString(data.packageContent ?? ''));
  formData.append('package_value', 1);
  formData.append('packaging_description', toUpperIfString(data.packagingDescription ?? ''));
  formData.append('delivery_type', data.deliveryType ?? '');
  formData.append('missing_product_value', 1);
  formData.append('missing_product_details', productDetails);
  formData.append('missing_product_description', productDescription);
  formData.append('quantity_packages', 1);
  formData.append('total_weight_packages', 1);
  // evidencia
  formData.append('invoice_doc', data.invoiceFile);
  formData.append('evidence_1', data.photograph1 ?? '');
  formData.append('evidence_2', data.photograph2 ?? '');
  formData.append('evidence_3', data.photograph3 ?? '');
  formData.append('evidence_4', data.photograph4 ?? '');
  formData.append('evidence_5', data.photograph5 ?? '');
  formData.append('evidence_6', data.photograph6 ?? '');
  formData.append('evidence_7', data.protectionPhoto ?? '');
  formData.append('evidence_8', data.contentPhoto ?? '');
  formData.append('evidence_9', data.label ?? '');
  // formData.append('evidence_10', data.);
  // additional
  formData.append('additional_comments', toUpperIfString(data.comment));
  // console.log('FormData contents:', [...formData.entries()]);
  try {
    const response = await apiV1AxiosInstanceForm.post('/tickets/soporte', formData, {
      timeout: 35000,
    });
    // console.log('-------- createSupportTickets response  ---------->', response.data);
    return response.data;
  } catch (error) {
    // console.log('-------- 1 creategetSupportTickets  catch err ---------->', error);
    if (error.response) {
      return error.response.data;
    }
    throw error;
  }
};
// deactivateService
const deactivateService = (shippingCode) =>
  apiV1AxiosInstance('/block-service', {
    method: 'POST',
    data: {
      shipping_code: shippingCode,
    },
  })
    .then((response) => {
      // console.log('-------- deactivateService res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- deactivateService catch err ---------->', error.response.data);
      return error.response;
    });

const createFinaceTicket = async (data) => {
  // Create an object FormData
  const formData = new FormData();
  // Add form's fields to FormData
  formData.append('shipping_id', data.labelId);
  formData.append('package_carrier', data.serviceName);
  formData.append('package_content', data.packageContent);
  formData.append('incident_type', data.incidentType);
  // formData.append('date_request', data.);
  // formData.append('date_create', data.);
  // formData.append('measures', data.);
  // formData.append('weight', data.);
  // formData.append('cost', data.);
  // formData.append('account_info', data.);
  // formData.append('reason_request', data.);
  // formData.append('long_pic', data.);
  // formData.append('width_pic', data.);
  // formData.append('height_pic', data.);
  // formData.append('guide_pic', data.);
  // formData.append('weight_pic', data.);
  // formData.append('', data.);

  // Use Axios Instance to send the data
  try {
    const response = await apiV1AxiosInstanceForm.post('/tickets/finanzas', formData, {
      timeout: 35000,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};
const getFinancePdf = (doc, folio) =>
  apiV1AxiosInstance(`/get-${doc}-pdf`, {
    method: 'POST',
    data: {
      folio_number: folio,
    },
  })
    .then((response) => {
      // console.log('-------- getFinancePdf res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getFinancePdf catch err ---------->', error.response.data);
      return error.response.data;
    });
const getFinanceXml = (doc, folio) =>
  apiV1AxiosInstance(`/get-${doc}-xml`, {
    method: 'POST',
    data: {
      folio_number: folio,
    },
  })
    .then((response) => {
      // console.log('-------- getFinanceXml res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getFinanceXml catch err ---------->', error.response.data);
      return error.response;
    });

// ****************** DELETE ********************************

const deleteAddress = (id) =>
  // addresses/delete/str:id.  PUT
  // /address/${id} DELETE anterior
  apiV1AxiosInstance(`/addresses/delete/${id}`, {
    method: 'PUT',
  })
    .then((response) => {
      // console.log('-------- deleteAddress response  ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- deleteAddress catch err ---------->', error.response.data);
      return error.response.data;
    });
const deleteParcel = (id) =>
  apiV1AxiosInstance(`/parcels/delete/${id}`, {
    method: 'PUT',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- deleteParcels catch err ---------->', error.response.data);
      return error.response.data;
    });
// activateService
const activateService = (shippingCode) =>
  apiV1AxiosInstance('/unblock-service', {
    method: 'DELETE',
    data: {
      shipping_code: shippingCode,
    },
  })
    .then((response) => {
      // console.log('-------- activateServices response ---------->', response.data);

      return response.data;
    })
    .catch((error) => {
      // console.log('-------- activateServices catch err ---------->', error.response.data);
      return error.response.data;
    });
// ****************** PUT ********************************

const editAddress = (id, address) =>
  // /addresses/update/str:id
  // /address/${id}
  apiV1AxiosInstance(`/addresses/update/${id}`, {
    method: 'PUT',
    data: {
      street_number: address.streetNumber,
      company: address.company,
      phone: address.phone,
      street: address.street,
      zip_code: address.zip,
      email: address.email,
      reference: address.reference,
      neighborhood: address.neighborhood,
      name: address.name,
      address_type: address.addressType,
    },
  })
    .then((response) => {
      // console.log('-------- editAddress response  ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- editAddress catch err ---------->', error.response.data);
      return error.response.data;
    });
const editParcel = (id, parcel) =>
  apiV1AxiosInstance(`/parcels/update/${id}`, {
    method: 'PUT',
    data: {
      name: parcel.name,
      product_description: parcel.product_description,
      height: parcel.height,
      width: parcel.width,
      depth: parcel.depth,
      weight: parcel.weight,
      product_value: parcel.product_value || 0,
      insurance: parcel.insurance,
    },
  })
    .then((response) => {
      // console.log('-------- editParcels response ---------->', response);

      return response.data;
    })
    .catch((error) => {
      // console.log('-------- editParcels catch err ---------->', error.response.data);
      return error.response.data;
    });

export {
  login,
  getCounters,
  getZipCode,
  getRates,
  getShippingHistory,
  getShippingDetails,
  getFilteredHistory,
  getAddressesDirectory,
  getParcelDirectory,
  getStatementsHistory,
  getStatementsFiltered,
  getOverweightHistory,
  getPickups,
  getOrders,
  getFilteredOrders,
  getOrderById,
  getShippingPDF,
  getOrderZip,
  getAddresses,
  getAddressesDetail,
  getFilteredAddress,
  getParcels,
  getFilteredParcels,
  getSupportTickets,
  getTicketDetail,
  getResourcesForm,
  getAllServices,
  getAvailableServices,
  // xxx
  getDocuments,
  getShipment,
  getChargeConcepts,
  // CREATE
  createShipping,
  cancelShipping,
  createAddress,
  createParcel,
  createPickup,
  createOrder,
  getOrderExcel,
  createSupportTicket,
  createFinaceTicket,
  deactivateService,
  getFinancePdf,
  getFinanceXml,
  // DELETE
  deleteAddress,
  deleteParcel,
  activateService,
  // EDIT
  editAddress,
  editParcel,
};
