import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Dropdown } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// utils
import { handleCatchError } from 'utils/handleErrors';
// components
import LoadingPulse from '../../../components/loading/LoadingPulse';
import CardPickup from './components/CardPickup';
import LoadingCar from '../../../components/loading/LoadingCar';
import Paginate from '../../../components/Paginate';
import { ToastErrorApi } from '../../../components/alerts/toast';

// api
import { getPickups } from '../../../api/Api-user';

const Pickup = () => {
  const title = 'Recolectar';
  const description = 'Recolectar Plataforma Autopaquete';
  // -Local States
  const [pickups, setPickups] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [changePage, setChangePage] = useState(1); // para la peticion
  const [changeItemsNum, setChangeItemsNum] = useState(10); // para la peticion
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  // - Use references to do scroll Up
  const startRef = useRef(null);
  const settingPage = (limit) => {
    setChangePage(1);
    setChangeItemsNum(limit);
  };
  // - Get all pickups
  const handlerGetPickups = async () => {
    try {
      if (pickups) {
        setIsLoading(true);
      }
      const response = await getPickups(changePage, changeItemsNum);
      // console.log('response', response);
      if (response.pickups !== undefined) {
        setPickups(response.pickups);
        setCurrentPage(response.current_page); // para actualizar la vista
        setPageSize(response.page_size); // para actualizar la vista
        setTotalPages(response.total_pages);
        if (startRef.current && startRef.current.scrollIntoView) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (response.error) {
        setPickups(pickups || []);
        setTotalPages(0);
        const firstKey = Object.keys(response.error)[0];
        const errorMessage = response.error[firstKey][0].trim();
        if (!errorMessage.includes('No se encontraron envíos con los parámetros especificados.')) {
          // solo si es diferente a este error muestralo
          // es para que salga este mensaje cuando el historial esta vacio
          ToastErrorApi(response.error[firstKey]);
        } else {
          setMessage('Parece que aún no has realizado ninguna recolección. ¿Quieres empezar ahora?');
        }
      }
    } catch (error) {
      setPickups(pickups || []);
      handleCatchError(error);
      setMessage('');
      // ToastErrorCath();
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handlerGetPickups();
    // console.log("pag actual en la peticion:", currentPage)
  }, [changePage, changeItemsNum]);

  // console.log(pickups);
  return (
    <>
      {pickups === null && <LoadingCar />}
      {isLoading && <LoadingPulse />}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container" ref={startRef}>
          <Row className="g-0">
            {/* Title Start */}
            <Col className="col-auto mb-3 mb-sm-0 me-auto">
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Inicio</span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
            {/* Top Buttons Start */}
            <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
              <NavLink to="/envios/recolectar/programar" className="btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto">
                <CsLineIcons icon="plus" /> <span>Crear recolección</span>
              </NavLink>
            </Col>
            {/* Top Buttons End */}
          </Row>
        </div>

        <Row className="mb-3">
          <Col md="5" lg="3" xxl="2" className="mb-1">
            {/* Search Start */}
            {/* <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 shadow bg-foreground">
            <Form.Control type="text" placeholder="Buscar" />
            <span className="search-magnifier-icon">
              <CsLineIcons icon="search" />
            </span>
            <span className="search-delete-icon d-none">
              <CsLineIcons icon="close" />
            </span>
          </div> */}
            {/* Search End */}
          </Col>
          <Col md="7" lg="9" xxl="10" className="mb-1 text-end">
            {/* Length Start */}
            <Dropdown align={{ xs: 'end' }} className={`d-inline-block ms-1 ${pickups?.length < 10 && 'invisible'}`}>
              <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-20">
                {`${pageSize} Recolecciones`}
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow dropdown-menu-end">
                <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                  10 Recolecciones
                </Dropdown.Item>
                <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                  20 Recolecciones
                </Dropdown.Item>
                <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                  50 Recolecciones
                </Dropdown.Item>
                <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                  100 Recolecciones
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className={`text-muted mt-3 d-lg-none ${pickups?.length < 2 && 'invisible'}`}>{`Página ${currentPage}`}</div>
            {/* Length End */}
          </Col>
        </Row>

        <div style={{ minHeight: '50vh' }} id="cardContainer">
          {/* List Header Start */}
          <Row className="g-0 mb-2 d-none d-lg-flex">
            <div className={`text-muted mb-3 ${pickups?.length < 2 && 'invisible'}`}>{`Página ${currentPage}`}</div>
            <Col xs="auto" className="sw-11 d-none d-lg-flex" />
            <Col>
              <Row className="g-0 h-100 d-flex align-items-center custom-sort ps-5 pe-5 h-100 ">
                <Col xs="3" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                  <div className="text-muted text-small cursor-pointer">PAQUETERIA / ID RECOLECCIÓN</div>
                </Col>
                <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center">
                  <div className="text-muted text-small cursor-pointer">FECHA SOLICITUD</div>
                </Col>
                <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center">
                  <div className="text-muted text-small cursor-pointer">ID ENVÍO</div>
                </Col>
                <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center align-items-center">
                  <div className="text-muted text-small cursor-pointer">FECHA CONFIRMACIÓN</div>
                </Col>
                <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center">
                  <div className="text-muted text-small cursor-pointer">ESTADO</div>
                </Col>
                <Col xs="2" lg="1" className="d-flex flex-column pe-1 justify-content-center align-items-lg-center">
                  <div className="text-muted text-small cursor-pointer">PAQUETES</div>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* List Header End */}

          {/* List Items Start */}
          {pickups?.length === 0 && message ? (
            <div className="py-5 mt-5 d-flex flex-column justify-content-center align-items-center w-50 m-auto">
              <h3 className="text-center my-5">{message}</h3>
              <NavLink to="/envios/recolectar/programar" className="btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto">
                <CsLineIcons icon="plus" /> <span>Crear recolección</span>
              </NavLink>
            </div>
          ) : (
            <>
              {/* List pickups Items Start */}
              {pickups?.map((pickup) => (
                <CardPickup key={pickup.guide} pickup={pickup} />
              ))}

              {/* List pickups Items End */}
              {/* Pagination Start */}
              <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages < 2 && 'd-none'}`}>
                <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setChangePage} />
              </div>
              {/* Pagination End */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Pickup;
