import React from 'react';
import { Row, Col } from 'react-bootstrap';

const AdditionalChargesRow = () => {
  return (
    <Row className="g-0 h-100 px-4 mb-2  align-content-center d-none d-lg-flex align-items-center custom-sort">
      <Col md="3" className="d-flex flex-column mb-lg-0 pe-3">
        <div className="text-muted text-small">CONCEPTO</div>
      </Col>
      <Col md="1" className="d-flex flex-column pe-1 justify-content-center">
        <div className="text-muted text-small">FECHA</div>
      </Col>
      <Col md="2" className="d-flex flex-column pe-1 justify-content-center">
        <div className="text-muted text-small">REFERENCIA</div>
      </Col>
      <Col md="2" className="d-flex flex-column ps-4 pe-1 justify-content-center align-items-center">
        <div className="text-muted text-small">ESTADO</div>
      </Col>
      <Col md="2" className="d-flex flex-column pe-1 justify-content-center bg-successx">
        <div className="text-muted text-small">AUTOR</div>
      </Col>
      <Col md="1" className="d-flex flex-column pe-1 justify-content-center align-items-end">
        <div className="text-muted text-small" />
      </Col>
      <Col md="1" className="d-flex flex-column pe-1 justify-content-center align-items-end">
        <div className="text-muted text-small">CARGO</div>
      </Col>
    </Row>
  );
};

export default AdditionalChargesRow;
