import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Row, Col, Card, Button } from 'react-bootstrap';
// external libraries
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { motion } from 'framer-motion';
// utils
import { initialsUpperCase } from 'utils/textFormat';
import { handleAPIError, handleCatchError } from 'utils/handleErrors';
// api
import { getCounters } from '../../api/Api-user';
// components
import ShippingRefundModal from './components/ShippingRefundModal';
import VideoModal from './components/VideoModal';
import CounterCard from './components/CounterCard';
import Loading from '../../components/loading/LoadingRed';
import ShipmentCard from './components/ShipmentCard';
// import PickupCard from './components/PickupCard';
import DashboardCard from '../../components/cards/DashboardCard';
import IncidentTypeModal from '../support/components/IncidentTypeModal';
import NotificationGallery from '../../components/carousel/NotificationsGallery';
import WarningModal from './components/WarningModal';

const Dashboard = () => {
  const title = 'Inicio';
  const description = 'Inicio Plataforma Autopaquete';
  // const dispatch = useDispatch();
  const history = useHistory();
  // - Local States
  const [counters, setCounters] = useState({
    balance: 0,
    shippings: 0,
    pickups: 0,
  });
  const [lastShippings, setLastShippings] = useState([]);
  // const [lastPickups, setLastPickups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isAwareWarning, setIsAwareWarning] = useState(() => {
    return localStorage.getItem('isAwareWarning') === 'true';
  });

  const [isWarningModalOpen, setIsWarningModalOpen] = useState(!isAwareWarning);

  // - Global State from Store.js form Values
  const { isLogin, currentUser } = useSelector((state) => state.auth);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  console.log('current user:', currentUser);
  // console.log('token desde el storage:', JSON.parse(JSON.parse(localStorage.getItem('persist:starter-project')).auth).currentUser.api_key_production);
  if (!isLogin) {
    history.push('/login');
    // history.goBack();
  }
  const cardVariants = {
    hidden: { opacity: 0, y: 50 }, // Empieza invisible y desplazado hacia abajo
    visible: { opacity: 1, y: 0 }, // Se vuelve visible y se posiciona
  };
  const handleModalClose = () => {
    setIsAwareWarning(true);
    setIsWarningModalOpen(false);
    localStorage.setItem('isAwareWarning', 'true');
  };
  const handlerGetCounters = async () => {
    try {
      setIsLoading(true);
      const response = await getCounters();
      console.log('response counters:', response);
      if (response.current_balance !== undefined) {
        setCounters({
          balance: response.current_balance,
          shippings: response.shippings_counter_last_month,
          pickups: response.pickups_counter_last_month,
        });
        setLastShippings(response.shippings);
        // setLastPickups(response.pickups);
        setNotifications(response.notices);
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
      // ToastErrorCath();
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handlerGetCounters();
  }, []);

  // console.log('contadores:', counters);
  useEffect(() => {
    if (!isAwareWarning) {
      setIsWarningModalOpen(true);
    }
  }, [isAwareWarning]);
  return (
    <>
      <ShippingRefundModal setIsRefundModalOpen={setIsRefundModalOpen} isRefundModalOpen={isRefundModalOpen} />
      <IncidentTypeModal isModalOpen={isReportModalOpen} setIsModalOpen={setIsReportModalOpen} />
      {/* Warning Modal */}
      <WarningModal isOpen={isWarningModalOpen} onClose={handleModalClose} />
      <VideoModal
        isOpen={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
        videoId="YSbmX0oNCUU" // ID del video de YouTube
      />
      <HtmlHead title={title} description={description} />
      {/* Title Start */}
      <div className="page-title-container">
        <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
          <span className="align-middle text-small ms-1">&nbsp;</span>
        </NavLink>
        <h1 className="mb-0 pb-0 display-4 text-center text-sm-start lh-base" id="title">
          {`¡Bienvenid@ ${initialsUpperCase(currentUser.name)}! ✨ `}
        </h1>
      </div>
      {/* Title End */}
      {/* Counter Cards Start */}
      <motion.div
        initial={{ opacity: 0, y: 50 }} // Empieza transparente y desplazado hacia abajo
        animate={{ opacity: 1, y: 0 }} // Se muestra y vuelve a su posición
        transition={{ duration: 0.5, ease: 'easeOut' }} // Transición suave
      >
        <Row className="mb-5 g-2">
          <CounterCard icon="dollar" title="saldo actual" counter={counters.balance} tooltip="Ir a Historial movimientos" path="/finanzas/movimientos" />
          <CounterCard icon="credit-card" title="clabe stp" counter={currentUser.clabe} tooltip="Ir a Historial facturación" path="/finanzas/facturacion" />
          <CounterCard icon="shipping" title="Envíos del mes" counter={counters.shippings} tooltip="Ir a Historial de envíos" path="/envios/historial" />
          <CounterCard
            icon="destination"
            title="recolecciones del mes"
            counter={counters.pickups}
            tooltip="Ir a Historial de recolecciones"
            path="/envios/recolectar"
          />
        </Row>
      </motion.div>
      {/* Counter Cards End */}
      <Row className="bg-secondaryx g-0 d-flex align-items-center">
        {/* Last Shippings Start */}
        <Col sm="12" md="12" lg="6" xxl="7" className="mb-5 order-2 order-lg-1 bg-infox">
          <h2 className="small-title">Últimos Envíos Realizados</h2>
          <div style={{ minHeight: '150px' }} className=" bg-infox">
            {isLoading && (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                <Loading />
              </div>
            )}
            {!isLoading && lastShippings.length === 0 && (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                <p>No hay envíos recientes</p>
                {/* También puedes usar una imagen si prefieres */}
                {/* <img src="ruta_de_la_imagen" alt="No hay envíos" /> */}
              </div>
            )}
            {!isLoading &&
              lastShippings.length > 0 &&
              lastShippings.map((shipping, index) => (
                <motion.div
                  key={shipping.id}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    duration: 0.5,
                    delay: index * 0.2, // Retraso para animación secuencial
                  }}
                  variants={cardVariants}
                >
                  <ShipmentCard shipping={shipping} />
                </motion.div>
              ))}
          </div>
        </Col>
        {/* Last Shippings End */}
        {/* Notifications Start */}
        <Col sm="6" xxl="5" className="mb-5 ps-lg-3 pt-lg-3 order-1 order-lg-2 bg-successx">
          <h2 className="small-title d-none">Notificaciones</h2>
          <div className=" bg-infox" style={{ maxWidth: '746px' }}>
            <div style={{ minHeight: '150px' }} className="bg-successx">
              {isLoading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                  <Loading />
                </div>
              ) : (
                <motion.div
                  initial={{ opacity: 0, y: 50 }} // Empieza transparente y desplazado hacia abajo
                  animate={{ opacity: 1, y: 0 }} // Se muestra y vuelve a su posición
                  transition={{ duration: 0.5, ease: 'easeOut' }} // Transición suave
                >
                  {notifications.length > 0 && <NotificationGallery notifications={notifications} />}
                </motion.div>
              )}
            </div>
          </div>
        </Col>
        {/* Notifications End */}
      </Row>
      {/* Cards Start */}
      <Row className="bg-infox">
        <Col xs="12" sm="6" xl="3">
          <Card className="h-100-card hover-img-scale-up mx-auto mb-3" style={{ minHeight: '300px', maxWidth: '480px', backgroundColor: '#f1f1f1' }}>
            <img src="/img/autopaquete/enviar/manual.webp" className="card-img h-100 scale" alt="card image" />
            <Card.Body className="d-flex flex-column justify-content-between align-items-start  bg-transparent card-img-overlay">
              <div>
                <div className="cta-3">¿Nuevo por aquí?</div>
                <div className="mb-3 cta-3 text-primary">Introducción</div>
                <div className="text-alternate mb-4">Conoce las herramientas y uso de nuestra plataforma a través de este breve video.</div>
              </div>
              <Button
                variant="outline-primary"
                className="btn-icon w-auto btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-1"
                onClick={() => {
                  setIsVideoModalOpen(true);
                }}
              >
                <CsLineIcons icon="arrow-right" />
                <span className="ps-2">Ver manual</span>
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <DashboardCard
          img="/img/autopaquete/enviar/recarga.webp"
          title="¿Tienes dudas?"
          subTitle="Manual de usuario"
          description="Explora nuestra guía de preguntas frecuentes diseñada para resolver todas tus inquietudes."
          url="https://autopaquete-log.glide.page/dl/d0a5f4"
          btnTitle="Click aquí"
        />
        <Col xs="12" sm="6" xl="3">
          <Card className="h-100-card hover-img-scale-up mx-auto mb-3" style={{ minHeight: '300px', maxWidth: '480px', backgroundColor: '#f1f1f1' }}>
            <img src="/img/autopaquete/enviar/soporte.webp" className="card-img h-100 scale" alt="card image" />
            <Card.Body className="d-flex flex-column justify-content-between align-items-start  bg-transparent card-img-overlay">
              <div>
                <div className="cta-3">¿Tu paquete tiene incidencia?</div>
                <div className="mb-3 cta-3 text-primary">Realizar reporte</div>
                <div className="text-alternate mb-4">
                  Por favor compártenos la información requerida sobre tu paquete, nosotros haremos el reporte a la paquetería.
                </div>
              </div>
              <Button
                variant="outline-primary"
                className="btn-icon w-auto btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-1"
                onClick={() => {
                  setIsReportModalOpen(true);
                }}
              >
                <CsLineIcons icon="arrow-right" />
                <span className="ps-2">Nuevo Reporte</span>
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" sm="6" xl="3">
          <Card className="h-100-card hover-img-scale-up mx-auto mb-3" style={{ minHeight: '300px', maxWidth: '480px', backgroundColor: '#f1f1f1' }}>
            <img src="/img/autopaquete/enviar/reembolso.webp" className="card-img h-100 scale" alt="card image" />
            <Card.Body className="d-flex flex-column justify-content-between align-items-start  bg-transparent card-img-overlay">
              <div>
                <div className="cta-3">¿Guía sin usar?</div>
                <div className="mb-3 cta-3 text-primary">Reembolso de guías</div>
                <div className="text-alternate mb-4">
                  Nuestro proceso de reembolso de guías está cuidadosamente diseñado para ofrecerte una experiencia sin complicaciones.
                </div>
              </div>
              <Button
                variant="outline-primary"
                className="btn-icon w-auto btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-1"
                onClick={() => {
                  setIsRefundModalOpen(true);
                }}
              >
                <CsLineIcons icon="arrow-right" />
                <span className="ps-2">Click aquí</span>
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* Cards End */}
    </>
  );
};

export default Dashboard;
