import React from 'react';
import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import CsLineIcons from 'cs-line-icons/CsLineIcons';

const Header = ({ title, img, description }) => {
  const history = useHistory();
  return (
    <Row>
      <div
        className="muted-link pb-1 d-inline-block hidden breadcrumb-back cursor-pointer text-primary ms-lg-5 d-flex justify-content-start align-items-center m-auto"
        onClick={() => history.goBack()}
      >
        <CsLineIcons icon="chevron-left" size="14" />
        <span className="align-middle text-medium ms-1">Atrás</span>
      </div>
      <div className="d-flex flex-column align-items-center mx-auto bg-secondaryx">
        <img alt="img" src={img} width="100px" />
        <h1 className="display-5 text-bold"> {title}</h1>
        <div className="pre-wrap mt-5 bg-successx text-start text-alternate" style={{ maxWidth: '648px' }}>
          {description}
        </div>
      </div>
    </Row>
  );
};

export default Header;
