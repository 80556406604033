import { apiV1AxiosInstance, apiV1AxiosInstanceLogin, apiV1AxiosInstanceForm } from './http';

// Base URL://api.autopaquete.com.mx/v1
const loginAdmin = (username, password) =>
  apiV1AxiosInstanceLogin(`/admin-login`, {
    method: 'POST',
    data: {
      username,
      password,
    },
  })
    .then((response) => {
      // console.log('login admi response:', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('login admi catch:', error.response.data);

      return error.response.data;
    });
// ****************  GETS  ******************************
// admin-shippings
const getAdminShippings = (num, limit) =>
  apiV1AxiosInstance(`/admin-shippings/list/?page=${num}&page_size=${limit}`, {
    method: 'GET',
    timeout: 45000,
  })
    .then((response) => {
      // console.log('-------- getAdminShippings response  ---------->', response);
      return response.data;
      // return response;
    })
    .catch((error) => {
      // console.log('-------- getAdminShippings catch err.response ---------->', error);
      // console.log('-------- getAdminShippings catch err.config ---------->', error.config);
      // console.log('-------- getAdminShippings catch err.code ---------->', error.code);
      // console.log('-------- getAdminShippings catch err.request ---------->', error.request);
      // console.log('-------- getAdminShippings catch err.isAxiosError ---------->', error.isAxiosError);
      // console.log('-------- getAdminShippings catch err.toJSON ---------->', error.toJSON);
      // error = ['config', 'code', 'request', 'response', 'isAxiosError', 'toJSON']
      return error.response.data;
    });
const getAdminFilteredShippings = (num, limit, shippingId, crmId, nameTo, serviceName, supplier, startDate, endDate) =>
  apiV1AxiosInstance(
    `/admin-shippings/list/?shipping_id=${shippingId}&crm_user_id=${crmId}&to_name=${nameTo}&shipping_company=${supplier}&shipping_code=${serviceName}&date=${startDate}&end_date=${endDate}&page=${num}&page_size=${limit}`,
    {
      method: 'GET',
      timeout: 45000,
    }
  )
    .then((response) => {
      // console.log('-------- getAdminFilteredShippings response  ---------->', response);
      return response.data;
      // return response;
    })
    .catch((error) => {
      // console.log('-------- getAdminFilteredShippings catch err.response ---------->', error.response);
      return error.response.data;
    });
const getAdminShippingsByUserId = (num, limit, crmId) =>
  apiV1AxiosInstance(`/admin-shippings/list/?crm_user_id=${crmId}&page=${num}&page_size=${limit}`, {
    method: 'GET',
    timeout: 45000,
  })
    .then((response) => {
      // console.log('-------- getAdminShippingsByUserId response  ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getAdminShippingsByUserId catch err ---------->', error.response);
      return error.response.data;
    });
const getAdminDetailShipping = (labelId) =>
  apiV1AxiosInstance(`/admin-shippings/detail/${labelId}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getAdminDetailShipping ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // sconsole.log('-------- getAdminDetailShipping catch err ---------->', error.response);
      return error.response.data;
    });
// https://api.autopaquete.com.mx/v1/admin-additional-charges?charge_type=REDUCES_BALANCE
// https://api.autopaquete.com.mx/v1/admin-additional-charges?charge_type=INCREASES_BALANCE
const getChargeConcepts = (type) =>
  apiV1AxiosInstance(`/admin-additional-charges?charge_type=${type}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getChargeConcepts catch err ---------->', error.response);
      return error.response;
    });
const getServicesName = (num, limit) =>
  apiV1AxiosInstance(`/admin-shippings?page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getServicesName catch err ---------->', error.response);
      return error.response;
    });
// identifier can be user name or user id
const getUserAccounts = (num, limit, identifier = '') =>
  apiV1AxiosInstance(`/admin-customers?page=${num}&page_size=${limit}&term=${identifier}`, {
    method: 'GET',
    timeout: 35000,
  })
    .then((response) => {
      // console.log('-------- getUserAccounts response ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getUserAccounts catch err ---------->', error.response);
      return error.response.data;
    });
// user detail
const getUser = (crmId) =>
  apiV1AxiosInstance(`/admin-customers?page=1&page_size=1&term=${crmId}`, {
    method: 'GET',
    // timeout: 35000,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getUser catch err ---------->', error.response);
      return error.response;
    });
const getBillingDocs = (docType, crmId, page, limit) =>
  apiV1AxiosInstance(`/admin-${docType}?page=${page}&page_size=${limit}&crm_user=${crmId}`, {
    method: 'GET',
    timeout: 35000,
  })
    .then((response) => {
      // console.log('-------- getBillingDocs response  ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getBillingDocs catch err ---------->', error);
      return error.response;
    });
const getStatements = (crmId, page, limit) =>
  apiV1AxiosInstance(`/admin-statements/list/?page=${page}&page_size=${limit}&crm_user_id=${crmId}`, {
    method: 'GET',
    timeout: 35000,
  })
    .then((response) => {
      // console.log('-------- getStatements nuevo response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getStatements catch err ---------->', error.response.data);
      return error.response.data;
    });
const getOverweights = (crmId, page, limit) =>
  apiV1AxiosInstance(`/admin-overweights/list/?page=${page}&page_size=${limit}&crm_user_id=${crmId}`, {
    method: 'GET',
    timeout: 35000,
  })
    .then((response) => {
      // console.log('-------- getOverweights response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getOverweights catch err ---------->', error.response.data);
      return error.response.data;
    });

const getComments = (crmId, num, limit) =>
  apiV1AxiosInstance(`/admin-comments/${crmId}/comments?page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getComments catch err ---------->', error.response);
      return error.response.data;
    });
const getOverweightOrders = (num, limit) =>
  apiV1AxiosInstance(`/admin-overweights-orders/list/?page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getOverweightOrders response ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getOverweightOrders catch err ---------->', error.response);
      return error.response.data;
    });
const getOverweightOrderById = (orderId) =>
  apiV1AxiosInstance(`/admin-overweights-orders/detail/${orderId}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getOverweightOrderById response ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getOverweightOrderById catch err ---------->', error.response);
      return error.response.data;
    });
const getOrderExcel = (type) =>
  apiV1AxiosInstance('/download-format', {
    method: 'POST',
    data: {
      code_concept: type,
    },
  })
    .then((response) => {
      //  console.log('-------- getOrderExcel res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getOrderExcel catch err ---------->', error.response.data);
      return error.response;
    });
const getRateList = () =>
  apiV1AxiosInstance('/admin-ratelists', {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getRateList res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getRateList catch err ---------->', error.response);
      return error.response.data;
    });
const getShippingCompanies = () =>
  apiV1AxiosInstance('/carrier-data/shipping-company', {
    method: 'GET',
  })
    .then((response) => {
      // console.log('--------getShippingCompanies response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('--------getShippingCompanies  catch err ---------->', error.response);
      return error.response;
    });
const getShippingCodes = () =>
  apiV1AxiosInstance('/carrier-data/shipping-code', {
    method: 'GET',
  })
    .then((response) => {
      // console.log('--------getShippingCodes response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('--------getShippingCodes  catch err ---------->', error.response);
      return error.response;
    });

const getCancellations = (num, limit) =>
  apiV1AxiosInstance(`/admin-cancelations/list/?page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('--------getCancellations response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('--------getCancellations  catch err ---------->', error.response);
      return error.response;
    });
// state can be: refunded, canceled, consumed, done
// num, limit, page=${num}&page_size=${limit}
const getFilteredCancellations = (num, limit, identifier, state, boolean, startDate, endDate) =>
  apiV1AxiosInstance(`/admin-cancelations/list/?page=${num}&page_size=${limit}&term=${identifier}&${state}=${boolean}&date=${startDate}&end_date=${endDate}`, {
    method: 'GET',
  })
    .then((response) => {
      console.log('--------getFilteredCancellations response ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      console.log('--------getFilteredCancellations  catch err ---------->', error.response);
      return error.response;
    });
const getAllNotices = () =>
  apiV1AxiosInstance('/admin-notices/list/', {
    method: 'GET',
  })
    .then((response) => {
      // console.log('--------getAllNotices response ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('--------getAllNotices  catch err ---------->', error.response);
      return error.response;
    });
const getHomeCounters = () =>
  apiV1AxiosInstance('/admin-home-counters', {
    method: 'GET',
  })
    .then((response) => {
      // console.log('--------getHomeCounters response ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('--------getHomeCounters  catch err ---------->', error.response);
      return error.response;
    });

const getSellers = () =>
  apiV1AxiosInstance('/admin-sellers/list/', {
    method: 'GET',
  })
    .then((response) => {
      // console.log('--------getSellers response ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('--------getSellers  catch err ---------->', error.response);
      return error.response;
    });
// ****************** POSTS ********************************
const createUser = (data) =>
  apiV1AxiosInstance('/admin-user/create/', {
    method: 'POST',
    data: {
      crm_user_id: data.crmUser,
      seller_code: data.seller,
      customer_type: data.customerType,
      rates_list: data.rate,
      razon_social: data.razon,
      rep_legal: data.legalRep,
      rfc: data.rfc,
      regimen_fiscal: data.fiscal,
      zip_code: data.zip,
      email: data.email,
      password: data.password,
      valid_zoho: true,
    },
  })
    .then((response) => {
      // console.log('-------- createUser res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- createUser catch  err ---------->', error.response);
      return error.response.data;
    });
const editUserAccount = (data) =>
  apiV1AxiosInstance('/admin-user/update/', {
    method: 'POST',
    data: {
      crm_user_id: data.crmId,
      is_active: data.isAccountActive,
      seller_code: data.adviser,
      customer_type: data.customerType,
      rates_list: data.rate,
      regimen_fiscal: data.taxSystem,
      zip_code: data.zip,
      automatic_invoice: data.isAutoVouchers,
      automatic_balance: data.isAutoBalance,
      is_multipiece_available: data.isMultiPieces,
      label_type: data.labelType,
    },
  })
    .then((response) => {
      // ('-------- editUserAccount response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- editUserAccount r catch err ---------->', error.response.data);
      return error.response.data;
    });
const returnShipping = (id) =>
  apiV1AxiosInstance('/admin-shippings/update/refunded', {
    method: 'POST',
    data: {
      shipping_id: id,
    },
  })
    .then((response) => {
      // console.log('-------- returnShipping res ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- returnShipping catch err ---------->', error.response.data);
      return error.response.data;
    });
const addOverweight = (labelId, weightDiff, realWeight) =>
  apiV1AxiosInstance('/admin-charge-overweight', {
    method: 'POST',
    data: {
      shipping_id: labelId,
      weight_diff: weightDiff,
      real_weight: realWeight,
      // overweight_charged_check: true,
    },
  })
    .then((response) => {
      // console.log('-------- addOverweight res ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- addOverweight catch err ---------->', error.response.data);
      return error.response.data;
    });
const calculateOverweight = (labelId, weightDiff, realWeight) =>
  apiV1AxiosInstance('/admin-calculate-overweight', {
    method: 'POST',
    data: {
      shipping_id: labelId,
      weight_diff: weightDiff,
      real_weight: realWeight,
    },
  })
    .then((response) => {
      // console.log('-------- calculateOverweight res ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- calculateOverweight catch err ---------->', error.response.data);
      return error.response.data;
    });
const returnOverweight = (id) =>
  apiV1AxiosInstance('/admin-overweights/update/refunded', {
    method: 'POST',
    data: {
      overweight_id: id,
    },
  })
    .then((response) => {
      // console.log('-------- returnOverweight res ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- returnOverweight catch err ---------->', error.response.data);
      return error.response.data;
    });
const createOverweightOrder = (formData) =>
  apiV1AxiosInstance('/admin-overweights-orders', {
    method: 'POST',
    data: formData,
  })
    .then((response) => {
      // ('-------- addOverweight orders response ---------->', response);
      // apiV1AxiosInstanceForm ????  cambiar a esto?
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- addOverweight orders catch err ---------->', error.response);
      return error.response;
    });
const addAdditionalCharge = (crmId, identifier, chargeAmount, concept, referenceTrace) =>
  apiV1AxiosInstance('/admin-additional-charges', {
    method: 'POST',
    data: {
      crm_user_id: crmId,
      reference: identifier,
      reference_trace: referenceTrace,
      amount: parseFloat(chargeAmount),
      code_concept: concept,
    },
  })
    .then((response) => {
      // console.log('-------- addAdditionalCharge response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- addAdditionalCharge catch err ---------->', error.response.data);
      return error.response.data;
    });
const returnAdditionalCharge = (id) =>
  apiV1AxiosInstance('/admin-additional-charges/update/refunded', {
    method: 'POST',
    data: {
      additional_charge_id: id,
    },
  })
    .then((response) => {
      // console.log('-------- returnAdditionalCharge res ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- returnAdditionalCharge catch err ---------->', error.response.data);
      return error.response.data;
    });
const addIncreaseBalance = (chargeAmount, concept, crmId, reference) =>
  apiV1AxiosInstance('/admin-additional-charges', {
    method: 'POST',
    data: {
      crm_user_id: crmId,
      reference,
      amount: parseFloat(chargeAmount),
      code_concept: concept,
    },
  })
    .then((response) => {
      // console.log('-------- addIncreaseBalance res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- addIncreaseBalance catch err ---------->', error.response.data);
      return error.response.data;
    });
const addComment = (crmId, comment) =>
  apiV1AxiosInstance('/admin-comments', {
    method: 'POST',
    data: {
      user: crmId,
      content: comment,
    },
  })
    .then((response) => {
      //  console.log('-------- addComment res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- addComment catch err ---------->', error.response.data);
      return error.response.data;
    });
const createNotice = (formData) =>
  apiV1AxiosInstanceForm('/admin-notices/create', {
    method: 'POST',
    data: formData,
  })
    .then((response) => {
      //  console.log('-------- createNotice res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- createNotice catch err ---------->', error.response.data);
      return error.response.data;
    });
const getFinancePdf = (doc, folio) =>
  apiV1AxiosInstance(`/get-${doc}-pdf`, {
    method: 'POST',
    data: {
      folio_number: folio,
    },
  })
    .then((response) => {
      //  console.log('-------- getFinancePdf res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getFinancePdf catch err ---------->', error.response.data);
      return error.response.data;
    });
const getFinanceXml = (doc, folio) =>
  apiV1AxiosInstance(`/get-${doc}-xml`, {
    method: 'POST',
    data: {
      folio_number: folio,
    },
  })
    .then((response) => {
      //  console.log('-------- getFinancexml res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getFinancexml catch err ---------->', error.response.data);
      return error.response.data;
    });
// ****************** PUT ********************************
const editComment = (crmId, id, comment) =>
  apiV1AxiosInstance(`/admin-comments/${id}`, {
    method: 'put',
    data: {
      user: crmId,
      content: comment,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getEditCommnet catch err ---------->', error.response);
      return error.response.data;
    });
const toggleCancellationState = (shippingId, state) =>
  apiV1AxiosInstance('/admin-cancelations-status', {
    method: 'PUT',
    data: {
      shipping_id: shippingId,
      field: state,
    },
  })
    .then((response) => {
      // console.log('-------- toggleCancellationState res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- toggleCancellationState catch  err ---------->', error.response);
      return error.response?.data;
    });
const updateNotice = (id, formData) =>
  apiV1AxiosInstanceForm(`/admin-notices/update/${id}`, {
    method: 'PUT',
    data: formData,
  })
    .then((response) => {
      console.log('-------- updateNotice res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      console.log('-------- updateNotice catch  err ---------->', error.response);
      return error.response?.data;
    });
// ****************** DELETE ********************************
const deleteComment = (id) =>
  apiV1AxiosInstance(`/admin-comments/${id}`, {
    method: 'DELETE',
  })
    .then((response) => {
      // console.log('-------- getDeleteCommnet res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getDeleteCommnet catch err ---------->', error.response);
      return error.response.data;
    });

const deleteNotice = (id) =>
  apiV1AxiosInstance(`/admin-notices/delete/${id}`, {
    method: 'DELETE',
  })
    .then((response) => {
      // console.log('-------- deleteNotice res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- deleteNotice catch err ---------->', error.response);
      return error.response.data;
    });

export {
  loginAdmin,
  getAdminShippings,
  getAdminFilteredShippings,
  getAdminShippingsByUserId,
  getAdminDetailShipping,
  getUserAccounts,
  getChargeConcepts,
  getServicesName,
  getUser,
  getBillingDocs,
  getStatements,
  getOverweights,
  getOverweightOrders,
  getOverweightOrderById,
  getOrderExcel,
  getRateList,
  getShippingCompanies,
  getShippingCodes,
  getCancellations,
  getFilteredCancellations,
  getAllNotices,
  getHomeCounters,
  getSellers,
  createUser,
  editUserAccount,
  returnShipping,
  createOverweightOrder,
  addOverweight,
  calculateOverweight,
  returnOverweight,
  addAdditionalCharge,
  returnAdditionalCharge,
  addIncreaseBalance,
  getComments,
  addComment,
  createNotice,
  getFinancePdf,
  getFinanceXml,
  editComment,
  toggleCancellationState,
  updateNotice,
  deleteComment,
  deleteNotice,
};
