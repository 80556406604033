import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import moment from 'moment';

import { handleAPIError, handleCatchError } from 'utils/handleErrors';
import { getFinancePdf, getFinanceXml } from '../../../../../api/Api-user';

const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 0,
});
// getFinancePdf = (doc, folio)
const CarBilling = ({ data, doc }) => {
  const getDocName = () => {
    switch (doc) {
      case 'invoice':
        return 'Factura';
      case 'rep':
        return 'Rep';
      case 'receipt':
        return 'Recibo';

      default:
        return 'Documento';
    }
  };
  const handlerGetPdf = async () => {
    try {
      const response = await getFinancePdf(doc, data.folio_number);
      console.log('handlerGetPdf  response :', response);

      if (response.pdf) {
        // Convertir el base64 en un archivo descargable
        const link = document.createElement('a');
        link.href = `data:application/pdf;base64,${response.pdf}`;
        link.download = `${getDocName()}-${data.folio_number}.pdf`; // Nombre del archivo descargado
        link.click();
      }
      if (response.error) {
        console.log('Error al obtener el PDF API response:', response.error);
        handleAPIError(response);
      }
    } catch (error) {
      console.error('Error al obtener el PDF:', error);
      handleCatchError();
    }
  };

  const handlerGetXml = async () => {
    try {
      const response = await getFinanceXml(doc, data.folio_number);
      // console.log('handlerGetXml response:', response);
      if (response.xml) {
        // Decodificar el Base64 en texto plano
        const decodedXml = atob(response.xml);
        // Crear un Blob a partir del contenido decodificado
        const blob = new Blob([decodedXml], { type: 'application/xml' });

        // Generar una URL temporal para el archivo
        const url = URL.createObjectURL(blob);
        // Crear un enlace temporal para descargar el archivo
        const link = document.createElement('a');
        link.href = url;
        link.download = `${getDocName()}-${data.folio_number}.xml`;
        // Simular clic en el enlace para iniciar la descarga
        document.body.appendChild(link); // Necesario para Safari
        link.click();
        // Limpiar el DOM y liberar la URL creada
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else if (response.error) {
        console.error('Error en la respuesta de la API:', response.error);
        handleAPIError(response.error);
      }
    } catch (error) {
      console.error('Error al obtener el archivo XML:', error);
      handleCatchError();
    }
  };

  return (
    <Card className="mb-2 ps-0">
      <Row className="g-0 h-100 sh-lg-9 position-relative">
        <Col className="py-4 py-lg-0 ps-4 pe-4 h-100">
          <Row className="g-0 h-100 align-content-center">
            <Col xs="6" lg="1" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-1">
              <div className="text-muted text-small d-lg-none">Folio</div>
              <div className="text-primary">{data.folio_number}</div>
            </Col>
            <Col xs="9" lg="2" className="d-flex flex-column align-items-start align-items-lg-center justify-content-center mb-2 mb-lg-0 order-4 order-lg-2">
              <div className="text-muted text-small d-lg-none">Fecha</div>
              <div className="text-alternate">{moment(data.created).format('DD-MM-YY')}</div>
            </Col>
            <Col xs="12" lg="4" className="d-flex flex-column my-3 my-lg-0 pe-2 order-3">
              <div className="text-muted text-small d-lg-none">Razón Social</div>
              <div className="text-alternate">{data.legal_name}</div>
            </Col>
            <Col xs="3" lg="2" className="d-flex flex-column align-items-start align-items-lg-center justify-content-center mb-2 mb-lg-0 order-5">
              <div className="text-muted text-small d-lg-none">Monto</div>
              <div className="text-alternate">
                <span className="text-small text-muted">$</span>
                {fmDecimal.from(data.amount)}
              </div>
            </Col>
            <Col xs="12" lg="1" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-last">
              <div className="text-muted text-small d-lg-none">Estado</div>
              <div className="text-alternate">{data.status}</div>
            </Col>
            <Col xs="6" lg="2" className="d-flex align-items-center justify-content-end mb-2 mb-lg-0 order-2 order-lg-last">
              {/* <a href={`data:application/pdf;base64,${data.pdf}`} download={`${doc}-${data.folio_number}`} className="download-pdf">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-filetype-pdf ms-3" viewBox="0 0 16 16">
                  <path d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                </svg>
              </a> */}
              <div
                className="cursor-pointer btn-link"
                onClick={() => {
                  handlerGetPdf();
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-filetype-pdf ms-3" viewBox="0 0 16 16">
                  <path d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                </svg>
              </div>
              {doc !== 'receipt' && (
                <div
                  className="cursor-pointer btn-link"
                  onClick={() => {
                    handlerGetXml();
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#007BFF" className="bi bi-filetype-xml ms-3" viewBox="0 0 16 16">
                    <path
                      fillRule="evenodd"
                      d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.527 11.85h-.893l-.823 1.439h-.036L.943 11.85H.012l1.227 1.983L0 15.85h.861l.853-1.415h.035l.85 1.415h.908l-1.254-1.992 1.274-2.007Zm.954 3.999v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596h-.025L4.58 11.85h-.806v3.999h.706Zm4.71-.674h1.696v.674H8.4V11.85h.791v3.325Z"
                    />
                  </svg>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default CarBilling;
