import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { Modal, Application } from 'react-rainbow-components';
import { Row, Col, Button, Card } from 'react-bootstrap';
// utils
import { handleAPIError, handleCatchError } from 'utils/handleErrors';

// components
import { ToastSuccess } from '../../../../components/alerts/toast';
// API
import { deleteAddress, getZipCode } from '../../../../api/Api-user';

const ModalAddress = ({ addressDetail, setIsModalOpen }) => {
  const [city, setCity] = useState({ name: '', state: '' });
  const [addressType, setAddressType] = useState('');
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

  const fullAddress = `${addressDetail.street} ${addressDetail.street_number}, ${addressDetail.neighborhood}, ${addressDetail.zip_code}.`;
  const theme = {
    rainbow: {
      palette: {
        brand: '#850000',
      },
    },
  };
  // useEffect Set Address Type
  useEffect(() => {
    if (addressDetail.address_type === 'RECEIVE') {
      setAddressType('DESTINO');
    } else {
      setAddressType('ORIGEN');
    }
  }, []);

  // Get Initial Letters Start
  let initialLetters = '';
  const words = addressDetail.name.split(' ');
  words.forEach((word) => {
    initialLetters += word.charAt(0); // Obtener la primera letra de cada palabra y agregarla a las iniciales
  });
  // Get Initial Letters End
  // Delete address
  const deleteAddressHandler = async () => {
    try {
      const response = await deleteAddress(addressDetail.id);
      // console.log('se elimino direccion, refresh page', response);
      setIsModalDeleteOpen(false);
      setIsModalOpen(false);
      if (response.is_deleted) {
        // response just with that is_delete : true
        setIsModalOpen(false);
        ToastSuccess('¡La dirección fue eliminada correctamente!');
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    }
  };
  // Get city and state
  useEffect(() => {
    const handlerGetCity = () => {
      getZipCode(addressDetail.zip_code).then((response) => {
        // console.log(response);
        if (response.length > 0) {
          setCity({ name: response[0].city, state: response[0].state.name });
        }
      });
    };
    handlerGetCity();
  }, []);
  // console.log('detalle de domicilio:', addressDetail);
  return (
    <>
      <Application theme={theme}>
        <Modal
          isOpen={isModalDeleteOpen}
          onRequestClose={() => setIsModalDeleteOpen(false)}
          borderRadius="rounded"
          title=""
          footer={
            <div className="d-flex justify-content-end ">
              <Button variant="outline-primary" className="me-2" onClick={() => deleteAddressHandler()}>
                Si, Eliminar
              </Button>
              <Button variant="primary" onClick={() => setIsModalDeleteOpen(false)}>
                Cancelar
              </Button>
            </div>
          }
        >
          <h2 className="fs-6 text-center my-4">{`¿Estás segur@ de que deseas eliminar la dirección ${addressDetail.name}?`}</h2>
        </Modal>
      </Application>
      <Card className="pb-5 px-3">
        <Card.Body className="mb-n5 px-0">
          <div className="d-flex align-items-center flex-column mb-5">
            <div className="mb-5 d-flex align-items-center flex-column">
              <div className="sw-6 sh-6 mb-5 d-inline-block bg-primary d-flex justify-content-center align-items-center rounded-xl">
                <div className="text-white">{initialLetters.substring(0, 2)}</div>
              </div>
              <div className="h5 mb-1 text-center">{addressDetail.name}</div>
              {city.name && (
                <div className="text-muted text-center">
                  <CsLineIcons icon="pin" className="me-1 mb-1" />
                  <span className="align-middle">{`${city.name}, ${city.state}.`}</span>
                </div>
              )}
            </div>
            <div className="d-flex flex-row justify-content-between w-auto">
              <Button
                className="me-2"
                variant="outline-primary"
                onClick={() => {
                  setIsModalDeleteOpen(true);
                }}
              >
                Eliminar
                {/* <CsLineIcons icon="bin" size="17" className="primary ms-2" /> */}
              </Button>
              {/* btn-icon btn-icon-start ms-0 ms-sm-1 to="/configuración/direcciones/editar"  */}
              <NavLink to={`/configuración/direcciones/editar/${addressDetail.id}`}>
                <Button className="me-2" style={{ minWidth: '99px' }}>
                  Editar
                  {/* <CsLineIcons icon="edit" size="17" variant="primary" className="primary ms-2" /> */}
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="mb-5 px-3">
            <p className="text-medium text-muted mb-2">{`DIRECCIÓN DE ${addressType}`}</p>
            <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="user" size="17" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">{addressDetail.name}</Col>
            </Row>
            {addressDetail.company && (
              <Row className="g-0 mb-2">
                <Col xs="auto">
                  <div className="sw-3 me-1">
                    <CsLineIcons icon="building" size="17" className="text-primary" />
                  </div>
                </Col>
                <Col className="text-alternate">{addressDetail.company}</Col>
              </Row>
            )}

            <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="pin" size="17" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">{fullAddress}</Col>
            </Row>
            <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="phone" size="17" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">{addressDetail.phone}</Col>
            </Row>
            {/* <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="email" size="17" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">{addressDetail.email}</Col>

            </Row> */}
            {addressDetail.reference && (
              <Row className="g-0 mb-2">
                <Col xs="auto">
                  <div className="sw-3 me-1">
                    <CsLineIcons icon="destination" size="17" className="text-primary" />
                  </div>
                </Col>
                <Col className="text-alternate pt-1">{addressDetail.reference}</Col>
              </Row>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ModalAddress;
