import React, { useState, useEffect, useRef } from 'react';
import { Card, Col, Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// utils
import { base64ToFile } from 'utils/base64ToFile';
import { handleAPIError, handleCatchError } from 'utils/handleErrors';
// components
import Header from './sections/Header';
import Footer from './sections/Footer';
import { ToastErrorCath } from '../../../components/alerts/toast';
import { getResourcesForm, createSupportTicket } from '../../../api/Api-user';
import Loading from '../../../components/loading/Loading';
import ReportInfo from './sections/ReportInfo';

const SupportForm = ({ children, formTitle, values, setValues, setErrors, errors, validationSchema }) => {
  const { generalData } = useSelector((state) => state.general_data);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  // - Use references to do scroll Up
  const startRef = useRef(null);

  const [resources, setResources] = useState({
    title: '',
    img: '',
    responseTime: '',
    description: '',
    warning: '',
  });
  const handleResourcesForm = async () => {
    try {
      const response = await getResourcesForm();
      const incidenceList = response.results;
      // console.log('getResourcesForm: ', response);
      const result = incidenceList.find((element) => element.title === formTitle);
      // console.log('recursos del formulario:', formTitle, ':', result);
      setResources({
        title: result.title,
        img: result.incidence_img,
        responseTime: result.description,
        description: result.header,
        warning: result.comment,
      });
    } catch {
      // console.log('getResourcesForm catch error ');
    }
  };

  const handleCreateReport = async () => {
    try {
      setIsLoading(true);
      // console.log('valores del form:', values);

      // Covert to file
      const labelFile = base64ToFile(values.label, `${values.shippingId}.pdf`);
      // Update values with new label file
      const updatedValues = {
        ...values,
        label: labelFile,
      };
      // console.log('Create ticket data:', updatedValues);
      const response = await createSupportTicket(updatedValues);
      // console.log('response support ticket:', response);
      if (response.ticket_info !== undefined) {
        toast.success(
          `¡Gracias por reportar el incidente: ${values.incidentType}! Tu reporte ha sido generado con éxito y tiene el ID ${response.ticket_info.ticket_number}. Puedes hacer clic en el nombre del reporte para consultar los detalles y las actualizaciones.`,
          {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          }
        );
        history.push('/reportes/historial');
      } else if (response.error) {
        // const firstKey = Object.keys(response.data.error)[0];
        // ToastErrorApi(response.data.error[firstKey]);
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };
  // Validation
  const validateForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(values, { abortEarly: false });

      handleCreateReport();
    } catch (error) {
      const formErrors = {};
      error.inner.forEach((err) => {
        formErrors[err.path] = err.message;
      });
      setErrors(formErrors);
      ToastErrorCath('Por favor, revisa y completa todos los campos obligatorios.');
    }
  };
  useEffect(() => {
    // Realizar el scroll cuando isVerifiedToReport es true
    if (generalData.fields.isVerifiedToReport && startRef.current) {
      startRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [generalData.fields.isVerifiedToReport]);
  useEffect(() => {
    handleResourcesForm();
  }, []);
  // console.log('Support Form:', values);
  return (
    <Form onSubmit={validateForm}>
      {isLoading && <Loading />}
      <Col xs="12" lg="10" className="col-lg mt-5">
        {/* Start */}
        <Card className="my-5">
          <Card.Body>
            <Header title={resources.title} img={resources.img} description={resources.description} />
            <ReportInfo values={values} setValues={setValues} setErrors={setErrors} errors={errors} />
          </Card.Body>
        </Card>
        <div ref={startRef} className="invisible" />
        <Card
          className={`p-0 card-transition ${generalData.fields.isVerifiedToReport ? 'visible' : 'hidden'}`}
          // className={`my-5 card-transition ${generalData.fields.isVerifiedToReport ? 'd-block' : 'd-none'}`}
        >
          <Card.Body>
            {/* <Header title={resources.title} img={resources.img} description={resources.description} /> */}
            {children}
            <Footer values={values} setValues={setValues} responseTime={resources.responseTime} warning={resources.warning} />
            <div className={`d-flex justify-content-center ${generalData.fields.isVerifiedToReport ? 'd-block' : 'd-none'}`}>
              <Button variant="primary" className="btn-icon btn-icon-start w-100 w-md-auto" type="submit">
                <CsLineIcons icon="chevron-right" /> <span>Crear Reporte</span>
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Form>
  );
};

export default SupportForm;
