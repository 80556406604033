import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
// External libraries
// api
import { getBillingDocs } from '../../../../../api/Api-admin';
// components
import Loading from '../../../../../../components/loading/LoadingRed';
import BillingRow from '../components/BillingRow';
import BillingCard from '../components/BillingCard';

const LastReps = ({ crmId }) => {
  const [reps, setReps] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const handleGetReps = () => {
      getBillingDocs('reps', crmId, 1, 5)
        .then((response) => {
          // console.log('Get reps res', response);
          if (response.data !== undefined) {
            setIsLoading(false);
            setReps(response.data);
            setMessage('No hay Reps registrados.');
          }
        })
        .catch(() => {
          setIsLoading(false);
          setReps([]);
          setMessage('Error: No se pudieron obtener los últimos Reps. Por favor, verifica tu conexión a Internet e inténtalo nuevamente.');

          // console.log('Err catch get reps', err);
        });
    };
    handleGetReps();
  }, []);

  return (
    <>
      {isLoading && (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <Loading />
        </Row>
      )}
      {reps?.length === 0 ? (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <h3>{message}</h3>
        </Row>
      ) : (
        <>
          {/* List Header Start */}
          {reps?.length > 0 && <BillingRow />}
          {/* List Header End */}
          {/* List Items Start */}
          {reps?.map((rep, index) => (
            <BillingCard data={rep} key={index} crmId={crmId} doc="rep" />
          ))}
          {/* List Items End */}
        </>
      )}
    </>
  );
};

export default LastReps;
