import { ToastErrorApi, ToastErrorCath } from '../components/alerts/toast';

const handleCatchError = (error) => {
  if (!navigator.onLine) {
    ToastErrorCath('No tienes conexión a Internet. Por favor, verifica tu red e inténtalo de nuevo.');
  } else if (error instanceof TypeError) {
    ToastErrorCath('Hubo un problema con la conexión. Por favor, inténtalo de nuevo.');
  } else {
    ToastErrorCath();
  }
};
const handleAPIError = (response) => {
  const firstKey = Object.keys(response.error)[0];
  ToastErrorApi(response.error[firstKey]);
};
// {"error": {"product_description": ["Este campo es requerido."]}, "error_code": "PARCELS-REQUEST-406"}. erro que si manejo

export { handleCatchError, handleAPIError };
