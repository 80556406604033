import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Row, Col, Dropdown, Form, Modal, Button } from 'react-bootstrap';
// utils
import { handleAPIError, handleCatchError } from 'utils/handleErrors';
// components
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import LoadingCar from '../../../components/loading/LoadingCar';
import LoadingPulse from '../../../components/loading/LoadingPulse';
import Paginate from '../../../components/Paginate';
import CardAddress from './components/CardAddress';
import ModalAddress from './components/ModalAddress';
import { ToastErrorCath } from '../../../components/alerts/toast';
// api
import { getAddresses, getFilteredAddress } from '../../../api/Api-user';

const Addresses = () => {
  const title = 'Direcciones';
  const description = 'Direcciones Plataforma Autopaquete';
  // - Local States
  const [addressesList, setAddressesList] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emptyHistory, setEmptyHistory] = useState(null);
  // - Start Filter States
  const [addressName, setAddressName] = useState('');
  // - End Filter States
  // - Start States for Components for Filtering
  const [filteredMode, setFilteredMode] = useState(false);
  const [filteredSuccess, setFilteredSuccess] = useState('');
  // - End States for Components for Filtering
  // - Start Pagination States
  const [currentPage, setCurrentPage] = useState(1); // for the view
  const [pageSize, setPageSize] = useState(10); // for the view
  const [changePage, setChangePage] = useState(1); // for the request
  const [changePageSize, setChangePageSize] = useState(10); // for the request
  const [totalPages, setTotalPages] = useState(0);
  // - End Pagination States
  // - Use references to do scroll Up
  const startRef = useRef(null);
  // - Global State
  const { addressDetail } = useSelector((state) => state.address_data);

  const handlerGetAddresses = async () => {
    try {
      if (addressesList) {
        // solo si addressesList es true [] activa este loader, si es falsy null usa el loader del car para los inicios
        setIsLoading(true);
      }
      const response = await getAddresses(changePage, changePageSize);
      // console.log('response', response);
      if (response.address !== undefined) {
        setAddressesList(response.address);
        setTotalPages(response.total_pages);
        setCurrentPage(response.current_page);
        setPageSize(response.page_size);
        if (startRef.current) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (response.address.length === 0) {
          // si el historial esta vacio regresa un []
          setEmptyHistory(addressesList || true); // para mostrar mensaje de "No tienes historial"
        }
      } else if (response.error) {
        handleAPIError(response);
        setAddressesList(addressesList || []); // si addressesList es falsy actualiza con [] para eliminar el loader
        setTotalPages(0);
      }
    } catch (error) {
      handleCatchError(error);
      setAddressesList(addressesList || []); // si addressesList es falsy actualiza con [] para eliminar el loader
    } finally {
      setIsLoading(false);
    }
  };
  const handlerGetFilteredAddress = async () => {
    // console.log('string:', string);
    if (!addressName) {
      setFilteredMode(false); // si changePage es mayor a 1 lo pone en true el btn buscar pero aqui es error esto es para que la paginacion siga funcionando en modo no filtrando
      ToastErrorCath('Por favor, ingresa un nombre para filtrar las direcciones.');
      return;
    }
    try {
      setIsLoading(true);
      const response = await getFilteredAddress(addressName, changePage, changePageSize);
      // console.log('handlerGetFilteredAddress', response);
      if (response.address) {
        setAddressesList(response.address);
        setTotalPages(response.total_pages);
        setCurrentPage(response.current_page);
        setPageSize(response.page_size);
        setFilteredSuccess(` Direcciones con el nombre: ${addressName}`);
        if (startRef.current) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
      setFilteredMode(true); // respuesta exitosa o no es necesario para mantener el modo filtrando y que aparezca el btn regresar
    }
  };

  const returnInitialMode = () => {
    // handlerGetFilteredHistory() o  handlerGetShippingHistory() activan el loader
    setFilteredMode(false);
    setAddressName('');
    setFilteredSuccess('');
    if (changePage > 1) {
      setChangePage(1); // ejecutara el useEffect que decide que fn ejecutar pero con la pag 1
    } else {
      // si changePage es 1 no ejecuta el cambio de estado ni el useEffect es necesario llamar a la funcion
      handlerGetAddresses();
    }
  };
  const settingPage = (limit) => {
    setIsLoading(true);
    setChangePage(1);
    setChangePageSize(limit);
  };
  useEffect(() => {
    if (filteredMode) {
      handlerGetFilteredAddress();
    } else {
      handlerGetAddresses();
    }
  }, [isModalOpen, changePage, changePageSize]);
  // console.log(addressesList)

  return (
    <>
      {addressesList === null && <LoadingCar />}
      {isLoading && <LoadingPulse />}
      {/* Address Modal Start */}
      <Modal className="modal-right" show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">Detalle</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <ModalAddress addressDetail={addressDetail} setIsModalOpen={setIsModalOpen} />
        </Modal.Body>
      </Modal>
      {/* Address Modal End */}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container" ref={startRef}>
          <Row className="g-0 mb-3 ">
            {/* Title Start col-auto */}
            <Col xs="auto" className="col-auto mb-sm-0 me-auto">
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Inicio</span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
            {/* button Add Address Start */}
            <Col xs="auto" className="d-flex align-items-end justify-content-end mt-3 mb-sm-0 order-sm-3">
              {/* d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3 */}
              <Button
                href="/configuración/direcciones/agregar"
                variant="outline-primary"
                className="btn-icon w-100 btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-1  w-md-auto"
              >
                <CsLineIcons icon="plus" /> <span> Agregar </span>
              </Button>
            </Col>
            {/* button Add Address End */}
          </Row>
        </div>
        <div style={{ minHeight: '50vh' }} id="cardContainer">
          <Row className="g-0 mb-3 mt-3">
            {/* Search Star */}
            <Col xs="12" sm="7">
              <Row className="g-0">
                <Col xs="12" lg="auto" className="mt-2 me-2">
                  {/* Search Start float-md-start */}
                  <div className="d-inline-block search-input-container shadow bg-foreground">
                    <Form.Control
                      type="text"
                      placeholder="Buscar por nombre"
                      value={addressName}
                      style={{ width: '275px' }}
                      onChange={(e) => {
                        setAddressName(e.target.value);
                      }}
                    />
                  </div>
                </Col>
                <Col xs="auto" className="pe-2 mt-2">
                  <Button
                    variant="primary"
                    style={{ height: '36px' }}
                    className="w-100"
                    onClick={() => {
                      if (changePage > 1) {
                        // la primera vez el estado filteredMode es falso
                        setFilteredMode(true); // para que el useEffect funcione bien
                        setChangePage(1); // ejecutara el useEffect que decide que fn ejecutar pero con la pag 1
                      } else {
                        handlerGetFilteredAddress();
                      }
                    }}
                  >
                    <span>Buscar</span>
                  </Button>
                </Col>
                {filteredMode && (
                  <Col xs="auto" className="mt-2">
                    <Button variant="outline-primary" style={{ height: '36px' }} className="w-100" onClick={returnInitialMode}>
                      <span>Regresar</span>
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
            {/* Search End */}
            {/* Items selector Start */}
            <Col xs="12" sm="5" lg="5" className={`mt-2 mt-sm-0 d-flex justify-content-end align-items-end ${addressesList?.length < 10 && 'd-none'} bg-infow`}>
              {/* Length Start */}
              <Dropdown align={{ xs: 'end' }} className="d-inline-block">
                <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-20">
                  {`${pageSize} Direcciones`}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow dropdown-menu-end">
                  <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                    10 Direcciones
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                    20 Direcciones
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                    50 Direcciones
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                    100 Direcciones
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {/* Items selector End */}
          </Row>
          <hr className="bg-muted mb-3 mt-4" />
          {filteredSuccess && <div className="text-muted mb-3">{filteredSuccess}</div>}
          <div className={`text-muted mb-3 text-end ${addressesList?.length < 2 && 'd-none'}`}>{`Página ${currentPage}`}</div>
          {/* List Header Start */}
          <Row className={`g-0 h-100 align-content-center d-none d-lg-flex ps-5 pe-5 mb-2 custom-sort ${addressesList?.length === 0 && 'invisible'}`}>
            <Col lg="3" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
              <div className="text-muted text-small cursor-pointer">NOMBRE</div>
            </Col>
            <Col lg="5" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointer">CALLE</div>
            </Col>
            <Col lg="2" className="d-lg-none d-flex flex-column pe-1 justify-content-center align-items-center">
              <div className="text-muted text-small cursor-pointer">NÚMERO</div>
            </Col>
            <Col lg="3" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointer">COLONIA</div>
            </Col>
            <Col lg="1" className="d-flex flex-column pe-1 justify-content-center align-items-end">
              <div className="text-muted text-small cursor-pointer">C.P</div>
            </Col>
          </Row>
          {/* List Header End */}
          {addressesList?.length === 0 ? (
            <div className="py-5 mt-lg-5 d-flex flex-column justify-content-center align-items-center w-70 m-auto">
              {emptyHistory && <h3 className="text-center mt-5">Tu historial de direcciones está vacío.</h3>}
              <img alt="empty" src="/img/animation/userNotFound.webp" style={{ width: '150px', height: '150px' }} />
            </div>
          ) : (
            <>
              {/* List Addresses Items Start */}
              {addressesList?.map((address) => (
                <CardAddress key={address.id} address={address} setIsModalOpen={setIsModalOpen} />
              ))}
              {/* List Addresses Items End */}

              {/* Pagination Start */}
              <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages < 2 && 'd-none'}`}>
                <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setChangePage} setIsLoading={setIsLoading} />
              </div>
              {/* Pagination End */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Addresses;
