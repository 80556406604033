/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// reducers
import { ToastErrorCath } from 'components/alerts/toast';
import { setGeneralData } from '../../../slices/generalData/generalData';

const IncidentTypeModal = ({ isModalOpen, setIsModalOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { generalData } = useSelector((state) => state.general_data);
  const options = [
    'Agilizar Entrega',
    'Cambio Domicilio',
    'Entrega Erronea',
    'Paquete Perdido o Percance Externo',
    'Paquete Dañado o Incompleto',
    'Retornar Paquete',
  ];
  const closeModal = () => {
    setIsModalOpen(false);
    dispatch(setGeneralData({ data: false, point: 'incidentType' }));
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Crear Reporte</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <select
          className="form-select"
          aria-label="Agilizar Entrega"
          onChange={(e) => dispatch(setGeneralData({ data: e.target.value, point: 'incidentType' }))}
        >
          <option selected>Selecciona el tipo de reporte</option>
          {options.map((value, index) => (
            <option key={index} value={value}>
              {value}
            </option>
          ))}
        </select>
      </Modal.Body>
      <Modal.Footer className="py-3">
        <Button
          className="btn-icon w-auto btn btn-icon btn-primary btn-icon-start ms-0 ms-sm-1"
          onClick={() => {
            if (generalData.fields.incidentType) {
              history.push('/reportes/crear-reporte');
            } else {
              ToastErrorCath('Error: Por favor elige un tipo de reporte');
            }
          }}
        >
          <CsLineIcons icon="arrow-right" />
          <span className="ps-2">Continuar</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default IncidentTypeModal;
