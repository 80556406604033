import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Dropdown, Form, Button } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import moment from 'moment';
// utils
import { cleanShippingId } from '../../../utils/textFormat';
import { handleCatchError, handleAPIError } from '../../../utils/handleErrors';
// components
// import { ToastErrorApi, ToastErrorCath } from '../../../components/alerts/toast';
import RainCalendar from '../../../components/calendars/RainCalendar';
import ShippingCard from './components/ShippingCard';
import DateModal from './components/DateModal';
import ShippingCSV from '../../../components/reactCsv/ShippingCSV';
import AdminLoader from '../../../components/loading/AdminLoader';
import LoadingPulse from '../../../components/loading/LoadingPulse';
import Paginate from '../../components/Paginate';

// api
import { getAdminShippings, getAdminFilteredShippings, getShippingCompanies, getShippingCodes } from '../../api/Api-admin';

const { FormatMoney } = require('format-money-js');

const Shippings = () => {
  const title = 'Historial Envíos';
  const description = 'Historial Envíos Plataforma Autopaquete';
  // - Use references to do scroll Up
  const startRef = useRef(null);
  // -Local States
  const [shippings, setShippings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInitialLoader, setIsInitialLoader] = useState(true);
  const [updatePage, setUpdatePage] = useState(false);
  // Start Pagination States
  const [currentPage, setCurrentPage] = useState(1); // solo para la vista
  const [pageSize, setPageSize] = useState(20); // solo para la vista
  const [totalItems, setTotalItems] = useState(0);
  const [changePage, setChangePage] = useState(currentPage); // para la peticion al inicio si valor es 1
  const [changeItemsNum, setChangeItemsNum] = useState(pageSize); // para la peticion al inicio si valor es 20
  const [totalPages, setTotalPages] = useState(null); // update with the response of request
  // End Pagination States
  // Start Filter States
  const [filteredMode, setFilteredMode] = useState(false);
  const [shippingId, setShippingId] = useState('');
  const [crmId, setCrmId] = useState('');
  const [nameTo, setNameTo] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [supplier, setSupplier] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  // End Filter States
  // Start States for Components for Filtering
  const [serviceList, setServiceList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [filteredSuccess, setFilteredSuccess] = useState('');
  const [selectStartDate, setSelectStartDate] = useState({ date: undefined });
  const [selectEndDate, setSelectEndDate] = useState({ date: undefined });
  // End States for Components for Filtering

  const fm = new FormatMoney({
    decimals: 0, // No decimales
    separator: ',', // Separador de miles
    decimalPoint: '.', // Punto decimal
    symbol: '', // Sin símbolo, ya que solo necesitas el número
  });
  // Clean Dates
  const initialDatesValues = () => {
    setSelectStartDate({ date: undefined });
    setSelectEndDate({ date: undefined });
    setStartDate('');
    setEndDate('');
  };
  // Reset Values
  const resetInitialValues = () => {
    initialDatesValues();
    setShippingId('');
    setCrmId('');
    setNameTo('');
    setSupplier('');
    setServiceName('');
    setFilteredSuccess(''); // para eliminar el mensaje "Filtro por nombre..."
  };

  const settingPage = (record) => {
    setIsLoading(true);
    setChangePage(1);
    setChangeItemsNum(record);
  };

  const filterByShippingId = () => {
    if (startDate) {
      setSelectEndDate({ date: undefined });
      setSelectStartDate({ date: undefined });
      setStartDate('');
      setEndDate('');
    }
  };
  // Get All Shippings
  const handlerGetShippings = async () => {
    try {
      setUpdatePage(false);
      setIsLoading(true);
      const response = await getAdminShippings(changePage, changeItemsNum);
      // console.log('get admin all shippings res:', response);
      if (response.shippings !== undefined) {
        setShippings(response.shippings);
        setTotalPages(response.total_pages);
        setTotalItems(response.total_items);
        setCurrentPage(response.current_page); // actualizar la vista de la paginacion
        setPageSize(response.page_size); // para actualizar la vista
        if (startRef.current) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      // console.log('error catch get all shippings');
      handleCatchError(error);
    } finally {
      setIsLoading(false);
      setIsInitialLoader(false);
    }
  };
  // Get filtered shippings
  const handlerGetFilteredShippings = async () => {
    try {
      setIsLoading(true);

      const service = serviceName.replace(/\s+/g, '_'); // add underscores to make request
      const response = await getAdminFilteredShippings(changePage, changeItemsNum, shippingId, crmId, nameTo, service, supplier, startDate, endDate);
      // console.log('get admin all handlerGetFilteredShippings res:', response);
      if (response.shippings !== undefined) {
        // console.log('Envios filtrados res:', response);
        setShippings(response.shippings);
        setTotalPages(response.total_pages);
        setTotalItems(response.total_items);
        setCurrentPage(response.current_page); // actualizar la vista de la paginacion
        setPageSize(response.page_size); // para actualizar la vista
        setFilteredSuccess(
          `Filtro ${shippingId ? `id envío : ${shippingId}` : ''} ${crmId ? `crm id: ${crmId}` : ''}  ${nameTo ? `nombre destinatario: ${nameTo}` : ''} ${
            supplier ? `proveedor: ${supplier}` : ''
          } ${serviceName ? `servicio: ${serviceName}` : ''} ${startDate ? ` del ${moment(startDate).format('DD-MM-YY')}` : ''} ${
            endDate ? `al ${moment(endDate).format('DD-MM-YY')}` : ''
          }`
        );
        if (startRef.current) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (response.error) {
        setShippings(shippings || []);
        setTotalPages(0);
        setFilteredSuccess(''); // para eliminar el mensaje "Filtro por servicio..."
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
      setFilteredMode(true); // respuesta exitosa o no es necesario para mantener el modo filtrando y que aparezca el btn regresar
    }
  };
  const resetInitialMode = () => {
    setFilteredMode(false);
    resetInitialValues();
    if (changePage > 1) {
      setChangePage(1); // ejecutara el useEffect que decide que fn ejecutar pero con la pag 1
    } else {
      // si changePage es 1 no ejecuta el cambio de estado ni el useEffect es necesario llamar a la funcion
      handlerGetShippings();
    }
  };
  useEffect(() => {
    getShippingCompanies().then((response) => {
      if (response?.list) {
        setSupplierList(response?.list);
      } else {
        setSupplierList([]);
      }
    });
    getShippingCodes().then((response) => {
      if (response?.list) {
        setServiceList(response?.list);
      } else {
        setServiceList([]);
      }
    });
  }, []);
  // - Whenever the pagination status change
  useEffect(() => {
    if (filteredMode) {
      handlerGetFilteredShippings();
    } else {
      handlerGetShippings();
    }
  }, [changePage, changeItemsNum]);
  useEffect(() => {
    if (updatePage) {
      if (filteredMode) {
        handlerGetFilteredShippings();
      } else {
        handlerGetShippings();
      }
    }
  }, [updatePage]);
  // console.log('modo filtrando?', filteredMode ? 'si' : 'no');
  // console.log(
  //   `estados de los filtros: envio- ${shippingId}. crm-${crmId}. proveedor-${supplier}. servicio-${serviceName}. nombre-${nameTo}. initDate-${startDate}. endDate-${endDate}.`
  // );
  return (
    <>
      {isLoading && <LoadingPulse />}
      {isModalOpen && <DateModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />}
      {isInitialLoader ? (
        <AdminLoader />
      ) : (
        <>
          <HtmlHead title={title} description={description} />
          <div className="page-title-container" ref={startRef}>
            <Row className="g-0">
              {/* Title Start */}
              <Col className="col-auto mb-3 mb-sm-0 me-auto order-1">
                <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back invisible" to="/">
                  <CsLineIcons icon="chevron-left" size="13" />
                  <span className="align-middle text-small ms-1">Envíos</span>
                </NavLink>
                <h1 className="mb-0 pb-0 display-4" id="title">
                  {title}
                </h1>
              </Col>
              {/* Title End */}
            </Row>
          </div>
          {/* este div es para ocultar el contenido cuando esta cargando  */}
          <div style={{ minHeight: '50vh' }} id="cardContainer">
            {/* *** Dropdown and Search Buttons Start *** */}
            <Row className="g-0 mb-2 mt-sm-5">
              {/* Filters Start */}
              <Col xs="auto" xl="9">
                {/* Id shipping, shipping code and shipping company Start */}
                <Row className="g-0">
                  <Col lg="auto" className="pe-2 mb-2">
                    <Form.Control
                      type="text"
                      style={{ fontFamily: 'Montserrat', width: '275px' }}
                      placeholder="ID Envío"
                      // readOnly={disabledSearchInput}
                      value={shippingId}
                      onChange={(e) => {
                        setShippingId(cleanShippingId(e.target.value));
                        filterByShippingId();
                      }}
                    />
                  </Col>
                  <Col lg="auto">
                    <Row className="g-0">
                      <Col xs="12" sm="auto" className="pe-2 mb-2">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="foreground-alternate"
                            className="shadow d-flex justify-content-between align-items-center"
                            style={{ fontFamily: 'Montserrat', width: '275px' }}
                          >
                            {serviceName || 'Servicio'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="shadow" align="end" style={{ fontFamily: 'Montserrat', maxHeight: '250px', overflowY: 'scroll' }}>
                            <Dropdown.Item onClick={() => setServiceName('')}>NINGUNO</Dropdown.Item>
                            {serviceList?.map((service) => (
                              <Dropdown.Item key={service} onClick={() => setServiceName(service)}>
                                {service}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                      <Col xs="12" sm="auto" className="mb-2 pe-2">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="foreground-alternate"
                            className="text-end shadow d-flex justify-content-between align-items-center w-100"
                            style={{
                              fontFamily: 'Montserrat',
                              minWidth: '200px',
                              maxWidth: '275px',
                            }}
                          >
                            {supplier || 'Proveedor'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="shadow" align="end" style={{ fontFamily: 'Montserrat', maxHeight: '250px', overflowY: 'scroll' }}>
                            <Dropdown.Item onClick={() => setSupplier('')}>NINGUNO</Dropdown.Item>
                            {supplierList?.map((company) => (
                              <Dropdown.Item key={company} onClick={() => setSupplier(company)}>
                                {company}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* Id shipping, shipping code and shipping company End */}
                {/* Name to, crm id and dates Start */}
                <Row className="g-0">
                  <Col xs="12" sm="auto" className="pe-2">
                    <Row className="g-0">
                      <Col xs="12" sm="auto" className="d-inline-block pe-2">
                        <Form.Control
                          type="text"
                          className="mb-2"
                          style={{ fontFamily: 'Montserrat', width: '275px' }}
                          placeholder="Crm Id"
                          // readOnly={disabledSearchInput}
                          value={crmId}
                          onChange={(e) => setCrmId(e.target.value)}
                        />
                      </Col>
                      <Col xs="12" sm="auto" className="d-inline-block">
                        <Form.Control
                          type="text"
                          className="mb-2"
                          style={{ fontFamily: 'Montserrat', width: '275px' }}
                          placeholder="Nombre Destinatario"
                          // readOnly={disabledSearchInput}
                          value={nameTo}
                          onChange={(e) => setNameTo(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row className="g-0">
                      <Col xs="auto" className="pt-0 mb-2 mb-xl-0 pe-2">
                        <RainCalendar
                          selectDate={selectStartDate}
                          setSelectDate={setSelectStartDate}
                          setState={setStartDate}
                          state={startDate}
                          holder="Fecha Inicial"
                          // label="Fecha Inicial"
                          // isDisabled={disabledCalendar}
                        />
                      </Col>
                      <Col xs="auto" className="mb-2 mb-xl-0">
                        <RainCalendar
                          selectDate={selectEndDate}
                          setSelectDate={setSelectEndDate}
                          setState={setEndDate}
                          holder="Fecha Final"
                          // label="Fecha Final"
                          // isDisabled={disabledCalendar}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* Filter buttons Start */}
                  <Col xs="12" sm="auto" className="pb-2 pb-xl-0 d-flex align-items-end">
                    <Row className="g-0">
                      <Col xs="auto" className="pe-2">
                        <Button
                          variant="primary"
                          style={{ height: '36px' }}
                          className="w-100 mb-0 mt-2"
                          onClick={() => {
                            // handlerGetFilteredShippings();
                            if (changePage > 1) {
                              // la primera vez el estado filteredMode es falso
                              setFilteredMode(true); // para que el useEffect funcione bien
                              setChangePage(1); // ejecutara el useEffect que decide que fn ejecutar pero con la pag 1
                            } else {
                              handlerGetFilteredShippings();
                            }
                          }}
                        >
                          <span>Buscar</span>
                        </Button>
                      </Col>
                      {filteredMode && (
                        <Col xs="auto" className="p-0">
                          <Button variant="outline-primary" style={{ height: '36px' }} className="w-100 mb-0 mt-2" onClick={resetInitialMode}>
                            <span>Regresar</span>
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  {/* Name to, crm id and dates Start */}
                </Row>
                {/* Filter buttons End */}
              </Col>
              {/* Filters End */}
              {/* Items selector Start */}
              <Col xs="12" xl="3" className="pb-2 pt-sm-5 pt-xl-0 pb-xl-0 d-flex justify-content-end align-items-end bg-infow">
                <Row className="g-0">
                  {/* Excel Buttons Start */}
                  <Col xs="auto">{shippings.length > 5 && <ShippingCSV shippings={shippings} crmId={crmId} />}</Col>
                  {/* Excel  Buttons End */}
                  <Col xs="auto" className="p-0 ms-2">
                    <Dropdown align={{ xs: 'end' }} className={`d-inline-block ${shippings.length <= 5 && 'd-none'}`}>
                      <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-15">
                        {`${pageSize} Envios`}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="shadow dropdown-menu-end">
                        <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                          20 Envíos
                        </Dropdown.Item>
                        <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                          50 Envíos
                        </Dropdown.Item>
                        <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                          100 Envíos
                        </Dropdown.Item>
                        <Dropdown.Item className={`${pageSize === 200 && 'd-none'}`} onClick={() => settingPage(200)}>
                          200 Envíos
                        </Dropdown.Item>
                        <Dropdown.Item className={`${pageSize === 300 && 'd-none'}`} onClick={() => settingPage(300)}>
                          300 Envíos
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* Length End */}
                  </Col>
                  {/* <div className={`text-end text-muted d-lg-none mt-3 mb-2 ${shippings?.length < 2 && 'invisible'}`}>{`Página ${currentPage}`}</div> */}
                </Row>
              </Col>
              {/* Items selector End */}
            </Row>
            <hr className="mt-3 mb-4 mt-xl-5 bg-muted" />
            {/* *** Dropdown and Search Buttons End *** */}
            {/* *** Main Page Start *** */}
            {/* List Header Start */}
            {filteredSuccess && <div className="text-muted mb-3">{filteredSuccess}</div>}
            <div className={`d-flex justify-content-between mb-3 text-muted  ${shippings?.length < 2 && 'd-none'}`}>
              <span>{`Total: ${fm.from(totalItems)}`}</span>
              <span>{`Página ${currentPage} `}</span>
            </div>

            <Row className={`g-0 mb-2 d-none d-lg-flex pt-2 pb-4 py-lg-0 ps-5 pe-4 custom-sort h-100 ${shippings.length === 0 && 'invisible'} `}>
              <Col lg="3" className="d-flex flex-column mb-lg-0 pe-3">
                <div className="text-muted text-small cursor-pointer">RAZÓN SOCIAL</div>
              </Col>
              <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
                <div className="text-muted text-small cursor-pointer">FECHA / HORA</div>
              </Col>
              <Col lg="3" className="d-flex flex-column align-items-center mb-lg-0 pe-3">
                <div className="text-muted text-small cursor-pointer">ID ENVÍO</div>
              </Col>
              <Col lg="3" className="d-flex flex-column pe-1 justify-content-center">
                <div className="text-muted text-small cursor-pointer">SERVICIO</div>
              </Col>
              <Col lg="1" className="d-flex flex-column pe-1 justify-content-center align-items-end">
                <div className="text-muted text-small cursor-pointer">COSTO</div>
              </Col>
              <Col lg="1" className="d-flex flex-column pe-1 align-items-end justify-content-center">
                <div className="invisible text-muted text-small cursor-pointer">MODAL</div>
              </Col>
            </Row>
            {/* List Header End */}
            {/* List shipment Start */}
            {shippings.length === 0 ? (
              <div className="d-flex flex-column align-items-center mt-lg-5">
                {/* <h3 className="text-center">No se encontraron registros.</h3> */}
                <img alt="sin datos" src="/img/animation/userNotfound.webp" className="w-20" />
              </div>
            ) : (
              shippings?.map((shipment, index) => <ShippingCard key={index} index={index} data={shipment} setUpdatePage={setUpdatePage} />)
            )}
            {/* List shipment End */}
            {/* Pagination Start */}
            <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages < 2 && 'd-none'}`}>
              <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setChangePage} />
            </div>
            {/* Pagination End */}
          </div>
          {/* *** Main Page End *** */}
        </>
      )}
    </>
  );
};

export default Shippings;
