import React from 'react';
import { NavLink } from 'react-router-dom';

import { Row, Col, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
// external libraries
import moment from 'moment';

const CardShipping = ({ pickup }) => {
  return (
    <Card className="mb-2">
      <Row className="g-0 h-100 sh-lg-9 position-relative">
        <Col xs="auto" className="positio-relative">
          <NavLink to="/envios/historial/detalle">
            <img src={pickup.service_img} alt="product" className="card-img card-img-horizontal sw-9 sw-sm-11 h-100" />
          </NavLink>
        </Col>
        <Col className="py-4 py-lg-0 ps-3 ps-sm-5 pe-2 pe-3 pe-sm-5 h-100">
          <Row className="g-0 h-100 align-content-center">
            <Col xs="12" lg="3" className="d-flex flex-column mb-lg-0 mb-2 pe-3 d-flex  h-lg-100 justify-content-center">
              <div className="text-muted text-small d-lg-none mb-1">Servicio / ID Recolección</div>
              <div className="p-0 pe-2 text-primary d-flex align-items-center">{pickup.shipping_code}</div>
              <div className="text-medium text-muted text-truncate">{`ID: ${pickup.pickup_id}`}</div>
            </Col>
            <Col xs="6" lg="2" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center ">
              <div className="text-muted text-small d-lg-none">Fecha Solicitud</div>
              <div className="lh-1 text-alternate">{moment(pickup.pickup_date).format('DD-MM-YYYY')}</div>
            </Col>

            <Col xs="6" lg="2" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
              <div className="text-muted text-small d-lg-none">ID Envío</div>
              <OverlayTrigger delay={{ show: 1000, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Ver detalle de envío</Tooltip>}>
                <NavLink to={`/envios/historial/detalle/${pickup.shipping_id}`}>{pickup.shipping_id}</NavLink>
              </OverlayTrigger>
            </Col>
            <Col xs="6" lg="2" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center align-items-lg-center">
              <div className="text-muted text-small d-lg-none">Fecha Confirmación</div>
              {pickup.pickup_folio_date && <div className="lh-1 text-alternate">{moment(pickup.pickup_folio_date).format('DD-MM-YYYY')}</div>}
            </Col>
            <Col xs="6" lg="2" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
              <div className="text-muted text-small d-lg-none">Estado</div>
              <div className="text-primary">{`${pickup.pickup_folio ? pickup.pickup_folio : ''}`}</div>
            </Col>
            <Col xs="12" lg="1" className="d-flex flex-column mb-2 mb-lg-0 align-items-end align-items-lg-center justify-content-center">
              <div className="text-muted text-small d-lg-none">Paquetes</div>
              <div className="text-alternate">{`${pickup.quantity}`}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
export default CardShipping;
