import React, { useEffect, useState } from 'react';

import { Row, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
// utils
import { cleanShippingId } from 'utils/textFormat';
import { handleAPIError, handleCatchError } from 'utils/handleErrors';
// components
import InputText from 'components/form-elements/InputText';
import { ToastErrorCath } from 'components/alerts/toast';
// import AutoFill from '../../../../components/form-elements/AutoFill';
import { getShippingDetails } from '../../../../api/Api-user';
// reducers
import { setGeneralData } from '../../../../slices/generalData/generalData';

const ReportInfo = ({ values, setValues, errors, setErrors }) => {
  const dispatch = useDispatch();
  const [isGettingData, setIsGettingData] = useState(false);
  const { currentUser } = useSelector((state) => state.auth);

  // Function to reset form values
  const resetFormValues = () => {
    setValues((prevValues) => {
      // Create a new object with properties reset to empty strings
      const resetValues = Object.keys(values).reduce((acc, key) => {
        // Maintain shippingId value and incidentType
        acc[key] = key === 'shippingId' || key === 'incidentType' ? prevValues[key] : '';
        return acc;
      }, {});
      // Return the object with the properties reset
      return resetValues;
    });
  };
  // Function to reset error values
  const resetFormErrors = () => {
    setErrors(() => {
      // Crear un nuevo objeto con las propiedades reseteadas a cadenas vacías
      const resetErrors = Object.keys(errors).reduce((acc, key) => {
        // shippingId as null instead of ''
        // acc[key] = key === 'shippingId' ? null : '';
        acc[key] = '';

        return acc;
      }, {});
      // Retornar el objeto con las propiedades reseteadas
      return resetErrors;
    });
  };
  const getData = async (inputValue) => {
    try {
      if (!inputValue) {
        ToastErrorCath('Error: Ingresa el ID del envío');
        return;
      }
      const cleanedInput = cleanShippingId(inputValue);
      // resetFormErrors(); // reset validation errors
      setIsGettingData(true);
      const response = await getShippingDetails(cleanedInput);
      // console.log('datos de la guia:', response);
      if (response.shipping_code !== undefined) {
        dispatch(setGeneralData({ data: true, point: 'isVerifiedToReport' }));
        setValues((prevValues) => ({
          ...prevValues,
          serviceName: response.shipping_company,
          razonSocial: response.razon_social,
          isInsurance: response.parcel.insurance,
          street: response.address_to.street,
          streetNumber: response.address_to.street_number,
          neighborhood: response.address_to.neighborhood,
          cp: response.address_to.zip_code,
          city: response.address_to.city,
          state: response.address_to.state,
          references: response.address_to.reference,
          phoneTo: response.address_to.phone,
          email: currentUser.email || '',
          label: response.label,
        }));
      } else if (response.error) {
        // handleError(response.error.shippings[0]); // para mostrar el error en texto debajo del input text
        resetFormErrors();
        resetFormValues();
        handleAPIError(response);
        dispatch(setGeneralData({ data: false, point: 'isVerifiedToReport' }));
      } else {
        resetFormValues();
      }
    } catch (error) {
      handleCatchError(error);
      dispatch(setGeneralData({ data: false, point: 'isVerifiedToReport' }));
    } finally {
      setIsGettingData(false);
    }
  };
  const updateTextField = (data, validation, fieldName) => {
    if (data === '' || validation.test(data)) {
      setValues((prevValues) => ({ ...prevValues, [fieldName]: data }));
    }
  };
  useEffect(() => {
    if (values.shippingId?.length === 0) {
      resetFormValues();
    }
  }, [values.shippingId]);
  useEffect(() => {
    return () => {
      // Código a ejecutar al salir de la página
      dispatch(setGeneralData({ data: false, point: 'incidentType' }));
    };
  }, []);
  return (
    <Row className="g-3 mt-5">
      <div className="d-flex flex-wrap position-relative mb-5 justify-content-md-start align-items-end mx-auto bg-secondaryx" style={{ maxWidth: '660px' }}>
        <div className="me-2 mb-2" style={{ width: '400px' }}>
          <InputText
            label="Ingresa el ID de tu envío"
            icon="edit-square"
            maxLength=""
            placeholder="ID Envío"
            value={values.shippingId}
            isRequired=""
            helpText=""
            fieldName="shippingId"
            cleanError=""
            setField={updateTextField}
            validation={/^[A-Z0-9 ]+$/i}
          />
        </div>

        <Button variant="primary" className="mb-2" size="lg" onClick={() => getData(values.shippingId)} style={{ height: '36px' }}>
          {isGettingData && <ClipLoader disabled={isGettingData} color="#FFFFFF" loading={isGettingData} size={10} />}
          {isGettingData ? 'Cargando' : 'Continuar'}
        </Button>
      </div>
    </Row>
  );
};

export default ReportInfo;
